import React, { FC, memo, useCallback } from 'react';
import { StyledItem } from '.';
import { Icon, Spacer, Tooltip } from 'ui';
import { ItemProps, QuestionNumberStyled } from '../';
import { LOGIC_OPTION_TYPES } from '~/pages/Builder23_1/constants/constants';

const Item: FC<ItemProps> = ({
	active,
	number,
	text,
	onClick,
	toId,
	value,
	disabled,
	tooltipContent,
}) => {
	const handleOnClick = useCallback(() => {
		onClick(toId);
	}, [onClick, toId]);

	return (
		<Tooltip
			description
			maxWidth={254}
			placement="top"
			disabled={!disabled}
			className="fullWidth"
			wrapperClassname="fullWidth"
			content={tooltipContent}
		>
			<StyledItem
				active={active}
				onClick={disabled ? undefined : handleOnClick}
				disabled={disabled}
			>
				<QuestionNumberStyled active={active} disabled={disabled}>
					{value === LOGIC_OPTION_TYPES.TO_PAGE ? <Icon name="page-thin-16" /> : number}
				</QuestionNumberStyled>
				<Spacer width={8} />
				{text}
				{active ? (
					<Icon name="checkmark-24" color="primary[800]" className="checkmark-icon" />
				) : null}
			</StyledItem>
		</Tooltip>
	);
};

export default memo(Item);
