import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { CategoryButton, LogicSelectContentStyled, LSContentLeftStyled, LSContentRight } from '.';
import { LogicSelectContentProps } from '.';
import {
	LOGIC_OPTION_FROM_API_TYPES_MAP,
	LOGIC_OPTION_TYPES as TYPES,
} from '~/pages/Builder23_1/constants/constants';
import { OptionValueType } from '~/pages/Builder23_1/components/LogicDialog/types';
import { useIntl } from 'react-intl';

const LogicSelectContent: FC<LogicSelectContentProps> = ({
	options,
	closeSelect,
	choiceId,
	logic,
	setChoiceLogic,
	logicValue,
}) => {
	const { formatMessage } = useIntl();
	const choiceLogic = useMemo(() => logic?.[choiceId] || logic, [choiceId, logic]);

	const hasToQuestion = options.some((option) => option.value === TYPES.TO_QUESTION);
	const hasToPage = options.some((option) => option.value === TYPES.TO_PAGE);
	const hasToUrl = options.some((option) => option.value === TYPES.TO_URL);
	const hasToSubmit = options.some((option) => option.value === TYPES.TO_SUBMIT);

	const [selected, setSelected] = useState<OptionValueType>(
		choiceLogic?.type
			? LOGIC_OPTION_FROM_API_TYPES_MAP[choiceLogic.type]
			: hasToQuestion
				? TYPES.TO_QUESTION
				: hasToPage
					? TYPES.TO_PAGE
					: TYPES.TO_URL,
	);

	const setToQuestion = useCallback(() => setSelected(TYPES.TO_QUESTION), []);
	const setToPage = useCallback(() => setSelected(TYPES.TO_PAGE), []);
	const setToUrl = useCallback(() => setSelected(TYPES.TO_URL), []);
	const setToSubmit = useCallback(() => setSelected(TYPES.TO_SUBMIT), []);

	return (
		<LogicSelectContentStyled>
			<LSContentLeftStyled>
				<CategoryButton
					label={formatMessage({ id: 'app.common.question' })}
					active={selected === TYPES.TO_QUESTION}
					onClick={setToQuestion}
					disabled={!hasToQuestion}
				/>
				<CategoryButton
					label={formatMessage({ id: 'app.common.page' })}
					active={selected === TYPES.TO_PAGE}
					onClick={setToPage}
					disabled={!hasToPage}
				/>
				{hasToUrl ? (
					<CategoryButton
						label={formatMessage({ id: 'app.designer.modal.website-link' })}
						active={selected === TYPES.TO_URL}
						onClick={setToUrl}
					/>
				) : null}
				{hasToSubmit ? (
					<CategoryButton
						label={formatMessage({ id: 'app.designer.modal.submit-button' })}
						active={selected === TYPES.TO_SUBMIT}
						onClick={setToSubmit}
					/>
				) : null}
			</LSContentLeftStyled>
			<LSContentRight
				selected={selected}
				options={options}
				closeSelect={closeSelect}
				choiceId={choiceId}
				setChoiceLogic={setChoiceLogic}
				logicValue={logicValue}
			/>
		</LogicSelectContentStyled>
	);
};

export default memo(LogicSelectContent);
