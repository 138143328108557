export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateTimeImmutable: { input: any; output: any; }
  HexRgbColor: { input: any; output: any; }
  ImageUpload: { input: any; output: any; }
  Json: { input: any; output: any; }
  LanguageCode: { input: any; output: any; }
  LocalUrl: { input: any; output: any; }
  LocaleCode: { input: any; output: any; }
  LogoUpload: { input: any; output: any; }
  PositiveNumber: { input: any; output: any; }
  ServicePeriod: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Url: { input: any; output: any; }
};

/** Activity event captured on team invitation acceptance */
export type AcceptingInvitationToSurvio = ActivityInterface & {
  __typename?: 'AcceptingInvitationToSurvio';
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

/** Where action should follow */
export enum ActionEnum {
  /** Skip to specific page */
  ToPage = 'TO_PAGE',
  /** Skip to specific question */
  ToQuestion = 'TO_QUESTION',
  /** Skip to submit */
  ToSubmit = 'TO_SUBMIT',
  /** Redirect to different site */
  ToUrl = 'TO_URL'
}

/** A connection to a list of items. */
export type ActivitiesConnection = {
  __typename?: 'ActivitiesConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ActivitiesEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ActivitiesEdge = {
  __typename?: 'ActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ActivityTypes>;
};

export type ActivityConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityFilter = {
  date?: InputMaybe<Scalars['Date']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Common activity fields */
export type ActivityInterface = {
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

/** Activity data are different for each type */
export type ActivityTypes = AcceptingInvitationToSurvio | CancellingInvitation | ChangeOfMemberRole | CreatingASurvey | DeletingASurvey | DeletingMemberSurveys | LoginTheTeamAccount | MemberDeactivation | MemberReactivation | MovingMemberSurveys | ReportDownload | SendingOutInvitations;

export type AddBackgroundInput = {
  background: Scalars['Upload']['input'];
  pageId?: InputMaybe<Scalars['ID']['input']>;
  uid: Scalars['String']['input'];
};

/** Status of the mutation request */
export type AddBackgroundResult = {
  __typename?: 'AddBackgroundResult';
  background?: Maybe<SurveyBackground>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type AddSurveyDashboardQuestionsInput = {
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  surveyDashboardId: Scalars['ID']['input'];
};

/** All possible question answer data types */
export type AllAnswerData = AnswerDataChoices | AnswerDataDividePoints | AnswerDataEmoji | AnswerDataMatrixChoiced | AnswerDataMatrixText | AnswerDataNps | AnswerDataOrder | AnswerDataRating | AnswerDataSemanticDifferential | AnswerDataText;

/** All survey item types (questions, text) */
export type AllContentItems = ChoiceQuestion | DateQuestion | DividePointsQuestion | EmojiQuestion | ImportanceQuestion | LocationQuestion | MatrixQuestion | NetPromoterQuestion | RatingQuestion | SemanticDifferentialQuestion | TextBlock | TextQuestion;

/** All survey item types (questions, text) */
export type AllQuestionAggregateItems = ChoicedQuestionAggregate | DividePointsQuestionAggregate | EmojiQuestionAggregate | ImportanceQuestionAggregate | MatrixQuestionAggregate | NetPromoterQuestionAggregate | SemanticDifferentialQuestionAggregate | StarRatingQuestionAggregate | TextQuestionAggregate;

export type AllQuestionMutationOutputs = ChoiceQuestion | DividePointsQuestion | ImportanceQuestion | MatrixQuestion | NetPromoterQuestion | RatingQuestion | SemanticDifferentialQuestion | TextBlock | TextQuestion;

export type AllowedChosenAnswerCountInput = {
  isEditedLimit?: InputMaybe<Scalars['Boolean']['input']>;
  maximumAnswers?: InputMaybe<Scalars['Int']['input']>;
  minimumAnswers: Scalars['Int']['input'];
};

/** Single IP address assigned to a survey. Respondents are allowed to access te survey only from an allowed IP. */
export type AllowedIp = {
  __typename?: 'AllowedIp';
  id: Scalars['Int']['output'];
  ip: Scalars['String']['output'];
};

/** List of selected choices */
export type AnswerDataChoices = {
  __typename?: 'AnswerDataChoices';
  list: Array<IndividualChoiceAnswer>;
};

/** Answer to divide points (rating scale) question */
export type AnswerDataDividePoints = {
  __typename?: 'AnswerDataDividePoints';
  items: Array<AnswerDataDividePointsItem>;
};

/** Points assigned to particular item */
export type AnswerDataDividePointsItem = {
  __typename?: 'AnswerDataDividePointsItem';
  assigned: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  item?: Maybe<QuestionSubitem>;
};

/** Answer to Emoji question */
export type AnswerDataEmoji = {
  __typename?: 'AnswerDataEmoji';
  maximum: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
};

/** Semantic differential value */
export type AnswerDataFractionalRating = {
  __typename?: 'AnswerDataFractionalRating';
  maximum: Scalars['Int']['output'];
  rating: Scalars['Float']['output'];
};

/** One answered matrix row, with one or more selected columns */
export type AnswerDataMatrixChoiceRow = {
  __typename?: 'AnswerDataMatrixChoiceRow';
  /** Selected columns */
  colIds: Array<Scalars['Int']['output']>;
  /** ID of the matrix row */
  rowId: Scalars['Int']['output'];
  /** Answer as a list of logic values, whether the particular choices were selected or not */
  selected: Array<Scalars['Boolean']['output']>;
};

/** one complete answer to matrix question */
export type AnswerDataMatrixChoiced = {
  __typename?: 'AnswerDataMatrixChoiced';
  rows: Array<AnswerDataMatrixChoiceRow>;
};

/** Answer to text matrix */
export type AnswerDataMatrixText = {
  __typename?: 'AnswerDataMatrixText';
  rows: Array<AnswerDataMatrixTextRow>;
};

/** One filled out row of the text matrix */
export type AnswerDataMatrixTextRow = {
  __typename?: 'AnswerDataMatrixTextRow';
  rowId: Scalars['Int']['output'];
  /** Individual cell values */
  texts: Array<Maybe<Scalars['String']['output']>>;
};

/** Answer to Net Promoter Score question */
export type AnswerDataNps = {
  __typename?: 'AnswerDataNPS';
  rating: Scalars['Int']['output'];
};

/** Answer to ordering question as a list of items from the highest importance (1st place) to least */
export type AnswerDataOrder = {
  __typename?: 'AnswerDataOrder';
  order: Array<Scalars['Int']['output']>;
};

/** Answer to star rating question */
export type AnswerDataRating = {
  __typename?: 'AnswerDataRating';
  maximum: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
};

/** Answer to semantic differential question */
export type AnswerDataSemanticDifferential = {
  __typename?: 'AnswerDataSemanticDifferential';
  list: Array<AnswerDataSemanticDifferentialRowAnswer>;
};

/** Answer to one semantic differential row */
export type AnswerDataSemanticDifferentialRowAnswer = {
  __typename?: 'AnswerDataSemanticDifferentialRowAnswer';
  answer?: Maybe<AnswerDataFractionalRating>;
  row?: Maybe<SemanticDifferentialRow>;
};

/** Simple raw text answer */
export type AnswerDataText = {
  __typename?: 'AnswerDataText';
  text: Scalars['String']['output'];
};

export type AnswerFilter = {
  __typename?: 'AnswerFilter';
  answer?: Maybe<Scalars['String']['output']>;
  answerOption?: Maybe<Scalars['ID']['output']>;
  answerTextValue?: Maybe<Scalars['String']['output']>;
  customAnswer?: Maybe<Scalars['Boolean']['output']>;
};

export type AnswerFilterInput = {
  /** If the answer is pointing to and id then the value should be this id. Otherwise it is either a text or some kind of other value. For semantic question it is the value, you can select and so. In case of matrix this value is id of column. */
  answer: Scalars['String']['input'];
  /** Some question are made from more than one parts e.g. Choice question is made of choices so this value would be choice id. In case of matrix - this value is the id of row. */
  answerOption?: InputMaybe<Scalars['ID']['input']>;
  /** If the answer is custom -> e.g. in choice question the custom answer. Basically it is mainly for text answers */
  customAnswer: Scalars['Boolean']['input'];
};

export type AnswerSegmentationSurveyInput = {
  data?: InputMaybe<Scalars['Json']['input']>;
  state?: InputMaybe<SegmentationSurveyStatusType>;
};

export type AnswerSegmentationSurveyResponse = {
  __typename?: 'AnswerSegmentationSurveyResponse';
  experiments?: Maybe<Array<Experiment>>;
  success: Scalars['Boolean']['output'];
};

export type AnswersFilter = {
  __typename?: 'AnswersFilter';
  answers: Array<AnswerFilter>;
  id: Scalars['ID']['output'];
  questionId: Scalars['ID']['output'];
  questionOrdinal: Scalars['Int']['output'];
};

/** Message sent to a user and archived for some time */
export type ArchivedMessage = {
  __typename?: 'ArchivedMessage';
  body: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
  type: EmailNotificationEnum;
};

export type AutomatedReports = {
  __typename?: 'AutomatedReports';
  enabled: Scalars['Boolean']['output'];
  repeat?: Maybe<AutomatedReportsRepeat>;
  to?: Maybe<Array<Scalars['String']['output']>>;
  when?: Maybe<Scalars['DateTimeImmutable']['output']>;
};

export enum AutomatedReportsRepeat {
  /** Send report daily */
  Daily = 'DAILY',
  /** Send report monthly */
  Monthly = 'MONTHLY',
  /** Send report only once */
  Once = 'ONCE',
  /** Send report weekly */
  Weekly = 'WEEKLY'
}

export type Availability = {
  __typename?: 'Availability';
  /** Survey should be online and collecting responses */
  active: Scalars['Boolean']['output'];
  /** allow access to survey only within dates in the fields "from" and "till" */
  deadlinesActive?: Maybe<Scalars['Boolean']['output']>;
  /** start of availability of the survey for public, format YYYY-MM-DD, at start of the day of the user's time zone */
  from?: Maybe<Scalars['String']['output']>;
  /**
   * Survey should be online and collecting responses
   * @deprecated not consistent with corresponding mutation field name
   */
  isActive: Scalars['Boolean']['output'];
  /** end of availability of the survey for public, format YYYY-MM-DD, at end of the day of the user's time zone. */
  till?: Maybe<Scalars['String']['output']>;
};

export type BackgroundVersions = {
  __typename?: 'BackgroundVersions';
  /** @deprecated Not used anymore */
  blur1?: Maybe<Scalars['LocalUrl']['output']>;
  /** @deprecated Not used anymore */
  blur2?: Maybe<Scalars['LocalUrl']['output']>;
  /** @deprecated Not used anymore */
  blur3?: Maybe<Scalars['LocalUrl']['output']>;
  original: Scalars['LocalUrl']['output'];
  /** @deprecated Not used anymore */
  prev_blur1?: Maybe<Scalars['LocalUrl']['output']>;
  /** @deprecated Not used anymore */
  prev_blur2?: Maybe<Scalars['LocalUrl']['output']>;
  /** @deprecated Not used anymore */
  prev_blur3?: Maybe<Scalars['LocalUrl']['output']>;
  preview: Scalars['LocalUrl']['output'];
};

export type BillingDate = {
  __typename?: 'BillingDate';
  amount: Scalars['Float']['output'];
  cardExpiration?: Maybe<Scalars['String']['output']>;
  currencyCode: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  plan?: Maybe<Plan>;
};

/** Number of respondents choosing one specific answer */
export type BreakdownItem = {
  __typename?: 'BreakdownItem';
  answer: TextAnswer;
  count: Scalars['Int']['output'];
};

/** Activity event captured when email invitation is cancelled */
export type CancellingInvitation = ActivityInterface & {
  __typename?: 'CancellingInvitation';
  data?: Maybe<CancellingInvitationData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type CancellingInvitationData = {
  __typename?: 'CancellingInvitationData';
  /** Email of cancelled invitation */
  email: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type CardConnection = {
  __typename?: 'CardConnection';
  /** Count of all surveys. Including active, not-active and deleted. */
  countOfAllSurveys: Scalars['Int']['output'];
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<CardEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CardEdge = {
  __typename?: 'CardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SurveyCard>;
};

/** Activity event captured when changing member's role */
export type ChangeOfMemberRole = ActivityInterface & {
  __typename?: 'ChangeOfMemberRole';
  data?: Maybe<ChangeOfMemberRoleData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type ChangeOfMemberRoleData = {
  __typename?: 'ChangeOfMemberRoleData';
  /** Member's email */
  email: Scalars['String']['output'];
  /** Member's full name */
  name?: Maybe<Scalars['String']['output']>;
  /** New member's role */
  role: Scalars['String']['output'];
};

export type ChartCustomLabels = {
  __typename?: 'ChartCustomLabels';
  answers?: Maybe<Scalars['Json']['output']>;
  axis?: Maybe<Scalars['Json']['output']>;
  columns?: Maybe<Scalars['Json']['output']>;
  rows?: Maybe<Scalars['Json']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ChartCustomLabelsInput = {
  answers?: InputMaybe<Scalars['Json']['input']>;
  axis?: InputMaybe<Scalars['Json']['input']>;
  columns?: InputMaybe<Scalars['Json']['input']>;
  rows?: InputMaybe<Scalars['Json']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Stores configuration of dashboard tile chart */
export type ChartSettings = {
  __typename?: 'ChartSettings';
  customLabels?: Maybe<ChartCustomLabels>;
  labels?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<OrderType>;
  percentage?: Maybe<Scalars['Boolean']['output']>;
  theme?: Maybe<ChartTheme>;
  type?: Maybe<ChartType>;
};

export type ChartSettingsInput = {
  applyThemeToAll?: InputMaybe<Scalars['Boolean']['input']>;
  customLabels?: InputMaybe<ChartCustomLabelsInput>;
  labels?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<OrderType>;
  percentage?: InputMaybe<Scalars['Boolean']['input']>;
  themeId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ChartType>;
};

/** Contains set of colors for charts */
export type ChartTheme = {
  __typename?: 'ChartTheme';
  /** Set of colors in hexadecimal format */
  colors?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  /** Locked theme cannot be edited by logged user */
  isLocked: Scalars['Boolean']['output'];
  /** True if theme is predefined, false it is custom created by user */
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ChartThemeInput = {
  /** Array of colors in hexadecimal format */
  colors: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export enum ChartType {
  /** Bar chart */
  Bar = 'BAR',
  /** Bar chart with negative values */
  Barnegative = 'BARNEGATIVE',
  /** Column chart */
  Column = 'COLUMN',
  /** Gauge chart */
  Gauge = 'GAUGE',
  /** NPS chart */
  Nps = 'NPS',
  /** Pie chart */
  Pie = 'PIE',
  /** Spider or radar chart */
  Spider = 'SPIDER',
  /** Table */
  Table = 'TABLE',
  /** Wordcloud */
  Wordcloud = 'WORDCLOUD'
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  url: Scalars['Url']['output'];
};

/** Answer choice for questions with fixed set of user-defined answers */
export type Choice = {
  __typename?: 'Choice';
  /** Filled if Multi-choice, Divide-points, Ordering question */
  collectionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** This option should provide a text box to enter a short comment */
  open: Scalars['Boolean']['output'];
  ordinal: Scalars['Int']['output'];
  /** Picture associated with the choice, in case of image based choices */
  picture?: Maybe<Picture>;
  /** Value assigned to the option for purpose of scoring of the responses */
  quizPoints: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

/** Question answered by selecting one or more choices, possibly filling in custom answer for some items */
export type ChoiceQuestion = ContentItem & QuestionInterface & {
  __typename?: 'ChoiceQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  choices?: Maybe<Array<Choice>>;
  /** Filled if single-choice question */
  collectionId?: Maybe<Scalars['String']['output']>;
  edited: Scalars['Boolean']['output'];
  /** Question contains choices with pictures attached */
  hasPictureChoices: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Limits in minimumAnswers and maximumAnswers were changed by the user */
  isEditedLimit: Scalars['Boolean']['output'];
  /** Respondents can select more than one option from the list, up to maximum specified with maximumAnswers */
  isMultipleAnswer: Scalars['Boolean']['output'];
  /** This question is part of a quiz and its choices are assigned numeric values */
  isQuizItem: Scalars['Boolean']['output'];
  /** Display style for the options */
  layout: LayoutEnum;
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  /** Respondents must pick at most this number of choices when answering the question */
  maximumAnswers?: Maybe<Scalars['Int']['output']>;
  /** Respondents must pick at least this number of choices when answering the question */
  minimumAnswers: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  text: Scalars['String']['output'];
  video?: Maybe<Video>;
};

/** Base options common for all question mutations */
export type ChoiceQuestionInput = {
  choices: Array<InputMaybe<ChoicesInput>>;
  chosenAnswerCount?: InputMaybe<AllowedChosenAnswerCountInput>;
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  isMultipleAnswer: Scalars['Boolean']['input'];
  isQuizItem?: InputMaybe<Scalars['Boolean']['input']>;
  layout: LayoutEnum;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** Choice question result summary with counts of how many respondents chose each answer option */
export type ChoicedQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'ChoicedQuestionAggregate';
  breakdown?: Maybe<Array<Maybe<BreakdownItem>>>;
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
};

export type ChoicesInput = {
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  open: Scalars['Boolean']['input'];
  ordinal: Scalars['Int']['input'];
  quizPoints?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

export type CloseInAppEmailInput = {
  id: Scalars['ID']['input'];
};

/** Choices for completeness filter */
export enum CompletenessEnum {
  All = 'ALL',
  Complete = 'COMPLETE',
  Discarded = 'DISCARDED',
  Incomplete = 'INCOMPLETE',
  Invalid = 'INVALID'
}

export type CompletenessFilter = {
  __typename?: 'CompletenessFilter';
  id: Scalars['ID']['output'];
  type?: Maybe<CompletenessEnum>;
};

/** Common attributes on item in the question pages */
export type ContentItem = {
  id: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

/** Status of the mutation request */
export type CopyMutationResult = {
  __typename?: 'CopyMutationResult';
  copy?: Maybe<AllQuestionMutationOutputs>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CopySurveyDashboardInput = {
  id: Scalars['ID']['input'];
};

export type CopySurveyInput = {
  title: Scalars['String']['input'];
  uid: Scalars['String']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type CountFilters = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  discarded?: InputMaybe<Scalars['Boolean']['input']>;
  invalid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export type CreateSurveyDashboardInput = {
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  surveyId: Scalars['ID']['input'];
};

export type CreateTeamInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['LanguageCode']['input'];
  name: Scalars['String']['input'];
  ownerLogin?: InputMaybe<Scalars['String']['input']>;
  sso?: InputMaybe<Scalars['Json']['input']>;
  totalSeatsCount?: InputMaybe<Scalars['PositiveNumber']['input']>;
};

/** Activity event captured when creating (copying) survey */
export type CreatingASurvey = ActivityInterface & {
  __typename?: 'CreatingASurvey';
  data?: Maybe<CreatingASurveyData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type CreatingASurveyData = {
  __typename?: 'CreatingASurveyData';
  /** Name of new survey */
  surveyName: Scalars['String']['output'];
};

export type CustomSurveyLanguage = {
  __typename?: 'CustomSurveyLanguage';
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sourceLanguage: SystemSurveyLanguage;
  translations: Scalars['Json']['output'];
  updated: Scalars['DateTime']['output'];
};

export type CustomSurveyLanguageInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  originalLanguageCode: Scalars['String']['input'];
  translations: Scalars['Json']['input'];
};

export type CustomUrl = {
  __typename?: 'CustomUrl';
  /** Unique noneditable prefix in case the custom part was aready used in a previous survey; URL ends with prefix/url if set */
  prefix?: Maybe<Scalars['String']['output']>;
  /** Customized part of the url */
  url: Scalars['String']['output'];
};

export type CustomUrlInput = {
  uid: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type DashboardFilters = {
  __typename?: 'DashboardFilters';
  answersFilters: Array<Maybe<AnswersFilter>>;
  completenessFilter?: Maybe<CompletenessFilter>;
  datePeriodFilters: Array<Maybe<SurveyDashboardDatePeriodFilter>>;
  durationFilters: Array<Maybe<ResponseDurationFilter>>;
  matches: Scalars['Int']['output'];
  sourceFilters: Array<Maybe<SourceFilter>>;
};

export type DashboardReportInput = {
  id: Scalars['ID']['input'];
  parts?: InputMaybe<ReportPartsInput>;
  type: ReportType;
};

export type DashboardSharing = {
  __typename?: 'DashboardSharing';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DashboardSharingInput = {
  dashboardId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type DashboardTileTypes = QuestionTile;

export type DatePeriodFilter = {
  __typename?: 'DatePeriodFilter';
  from: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  till: Scalars['DateTime']['output'];
};

export enum DatePeriodType {
  /** Retrieve responses based on custom range */
  CustomRange = 'CUSTOM_RANGE',
  /** Retrieve all responses */
  FromFirstResponse = 'FROM_FIRST_RESPONSE',
  /** Retrieve responses for last 7 days (incl. today) */
  Last_7Days = 'LAST_7_DAYS',
  /** Retrieve responses for last 30 days (incl. today) */
  Last_30Days = 'LAST_30_DAYS',
  /** Retrieve responses for previous month (first day of month - last day of month */
  PreviousMonth = 'PREVIOUS_MONTH',
  /** Retrieve responses for previous week (monday - sunday */
  PreviousWeek = 'PREVIOUS_WEEK',
  /** Today's responses */
  Today = 'TODAY'
}

/** Question answered by writing the answer in the text field */
export type DateQuestion = ContentItem & QuestionInterface & {
  __typename?: 'DateQuestion';
  answerLengthLimit: Scalars['Int']['output'];
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  /** @deprecated probably not used, will be removed */
  subtype: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textCollectionId: Scalars['String']['output'];
  type: TextQuestionEnum;
  video?: Maybe<Video>;
};

/** Defines delay from invitation's creation */
export type DelayInput = {
  amount: Scalars['Int']['input'];
  unit: TimeUnit;
};

/** Activity event captured when deleting survey */
export type DeletingASurvey = ActivityInterface & {
  __typename?: 'DeletingASurvey';
  data?: Maybe<DeletingASurveyData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type DeletingASurveyData = {
  __typename?: 'DeletingASurveyData';
  /** Name of deleted survey */
  surveyName: Scalars['String']['output'];
};

/** Activity event captured when deleting member's surveys */
export type DeletingMemberSurveys = ActivityInterface & {
  __typename?: 'DeletingMemberSurveys';
  data?: Maybe<DeletingMemberSurveysData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type DeletingMemberSurveysData = {
  __typename?: 'DeletingMemberSurveysData';
  /** Member's email */
  email: Scalars['String']['output'];
  /** Member's full name */
  name?: Maybe<Scalars['String']['output']>;
  /** Deleted surveys */
  surveyNames?: Maybe<Array<Scalars['String']['output']>>;
};

/** delivery and engagement status of individual recipient's invitation */
export enum DeliveryStatus {
  /** Recipient clicked the button */
  Clicked = 'CLICKED',
  /** Accepted by target mail server and delivered to the recipient */
  Delivered = 'DELIVERED',
  /** Could not deliver */
  Failed = 'FAILED',
  /** Recipient opened the message */
  Opened = 'OPENED',
  /** Being delivered */
  Pending = 'PENDING',
  /** Recipient submitted the filled out survey response */
  Submitted = 'SUBMITTED'
}

/** Result of one divide points item */
export type DividePointsItemResult = {
  __typename?: 'DividePointsItemResult';
  average: Scalars['Float']['output'];
  histogramData: Array<HistogramItem>;
  item: TextAnswer;
  sum: Scalars['Int']['output'];
};

/** Question anwered by splitting fixed number of points among several items */
export type DividePointsQuestion = ContentItem & QuestionInterface & {
  __typename?: 'DividePointsQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /** Number of units the respondents will be asked to divide */
  pointCount: Scalars['Int']['output'];
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  rows: Array<PresetRowTextAnswer>;
  text: Scalars['String']['output'];
  /** Name of the units the respondents will be asked to divide (e.g. points, dollars, etc.) */
  units: Scalars['String']['output'];
  video?: Maybe<Video>;
};

/** Summary result of divide points question answers */
export type DividePointsQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'DividePointsQuestionAggregate';
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
  results: Array<DividePointsItemResult>;
  units: Scalars['String']['output'];
};

export type DownloadDashboardReportInput = {
  date: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Time period given as number of elapsed hours, minutes after the last whole hour, and seconds after last minute ie. 5 hours 2 minutes 30 seconds mean 302.5 minutes or 18150 seconds */
export type Duration = {
  __typename?: 'Duration';
  hours: Scalars['Int']['output'];
  minutes: Scalars['Int']['output'];
  seconds: Scalars['Int']['output'];
  secondsTotal: Scalars['Int']['output'];
};

/** Choices for duration (time spent filling out a survez) filters */
export enum DurationEnum {
  FiveToTen = 'FIVE_TO_TEN',
  LessThanMinute = 'LESS_THAN_MINUTE',
  MoreThenSixty = 'MORE_THEN_SIXTY',
  OneToTwo = 'ONE_TO_TWO',
  TenToThirty = 'TEN_TO_THIRTY',
  ThirtyToSixty = 'THIRTY_TO_SIXTY',
  TwoToFive = 'TWO_TO_FIVE'
}

/** Intro or exit page mutation result */
export type EdgePageMutationResult = {
  __typename?: 'EdgePageMutationResult';
  /** Properties of a logo on the page, if any */
  logo?: Maybe<Logo>;
  success: Scalars['Boolean']['output'];
};

/** Input to set member's data */
export type EditMemberInput = {
  id: Scalars['ID']['input'];
  role: TeamMemberRole;
  userFullName?: InputMaybe<Scalars['String']['input']>;
};

export type EditSurveyDashboardInput = {
  id: Scalars['ID']['input'];
  logo?: InputMaybe<Scalars['LogoUpload']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum EmailNotificationEnum {
  Expiration_14Days = 'EXPIRATION_14DAYS',
  ExpirationToday = 'EXPIRATION_TODAY',
  LimitExceeded = 'LIMIT_EXCEEDED'
}

export enum EmojiColorEnum {
  FromTheme = 'FROM_THEME',
  GreenRed = 'GREEN_RED'
}

/** Emoji question, answered by choosing one of possible emojis */
export type EmojiQuestion = ContentItem & QuestionInterface & {
  __typename?: 'EmojiQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  emojiColor: EmojiColorEnum;
  emojiCount: Scalars['Int']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  leftText: Scalars['String']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  order: EmojisOrderEnum;
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  rightText: Scalars['String']['output'];
  text: Scalars['String']['output'];
  video?: Maybe<Video>;
};

/** Summary of Emoji question */
export type EmojiQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'EmojiQuestionAggregate';
  average: Scalars['Float']['output'];
  breakdown?: Maybe<Array<Maybe<BreakdownItem>>>;
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
};

/** Base options common for all question mutations */
export type EmojiQuestionInput = {
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  emojiColor: EmojiColorEnum;
  emojiCount: Scalars['Int']['input'];
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  leftText: Scalars['String']['input'];
  order: EmojisOrderEnum;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  rightText: Scalars['String']['input'];
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** Just an interface for questions */
export type EmojiQuestionOutput = {
  __typename?: 'EmojiQuestionOutput';
  edited?: Maybe<Scalars['Boolean']['output']>;
  emojiColor: EmojiColorEnum;
  emojiCount: Scalars['Int']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  leftText: Scalars['String']['output'];
  order: EmojisOrderEnum;
  ordinal?: Maybe<Scalars['Int']['output']>;
  page: Scalars['ID']['output'];
  picture?: Maybe<Scalars['ImageUpload']['output']>;
  required: Scalars['Boolean']['output'];
  rightText: Scalars['String']['output'];
  text: Scalars['String']['output'];
  video?: Maybe<YoutubeOutput>;
};

export enum EmojisOrderEnum {
  NegativeFirst = 'NEGATIVE_FIRST',
  PositiveFirst = 'POSITIVE_FIRST'
}

/** request to discard a response - after discarding it does not contribute to aggeregated question results nor is included in exports */
export type ExcludeResponsesInput = {
  discarded: Scalars['Boolean']['input'];
  responseIds: Array<Scalars['ID']['input']>;
  uid: Scalars['String']['input'];
};

export type Experiment = {
  __typename?: 'Experiment';
  data?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<ExperimentState>;
  variant?: Maybe<Scalars['String']['output']>;
};

export enum ExperimentState {
  Excluded = 'EXCLUDED',
  Finished = 'FINISHED',
  Included = 'INCLUDED',
  Skipped = 'SKIPPED'
}

export enum ExperimentStateInput {
  Finished = 'FINISHED',
  Skipped = 'SKIPPED'
}

export type ExportInput = {
  /** Whether to include all data in the export (only admin can use this input) */
  fullExport?: InputMaybe<Scalars['Boolean']['input']>;
  type: ExportType;
  uid: Scalars['String']['input'];
};

export type ExportStartResponse = {
  __typename?: 'ExportStartResponse';
  exportToken: Scalars['String']['output'];
  finished: Scalars['Boolean']['output'];
};

export enum ExportType {
  Csv = 'CSV',
  Excel = 'EXCEL',
  Sheets = 'SHEETS'
}

/** Statistic of how many respondents spent how much time filling out the survey */
export type FilloutDurationAggregate = {
  __typename?: 'FilloutDurationAggregate';
  breakdown: Array<FilloutDurationItem>;
  respondentsTotal: Scalars['Int']['output'];
};

export type FilloutDurationItem = {
  __typename?: 'FilloutDurationItem';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  value: DurationEnum;
};

/** Statistic of how many respondents finished a survey etc. */
export type FilloutStatusAggregate = {
  __typename?: 'FilloutStatusAggregate';
  breakdown: Array<FilloutStatusItem>;
  visitsTotal: Scalars['Int']['output'];
};

export type FilloutStatusItem = {
  __typename?: 'FilloutStatusItem';
  count: Scalars['Int']['output'];
  value: CompletenessEnum;
};

export type Filters = {
  __typename?: 'Filters';
  answersFilters: Array<Maybe<AnswersFilter>>;
  completenessFilter?: Maybe<CompletenessFilter>;
  datePeriodFilters: Array<Maybe<DatePeriodFilter>>;
  durationFilters: Array<Maybe<ResponseDurationFilter>>;
  matches: Scalars['Int']['output'];
  sourceFilters: Array<Maybe<SourceFilter>>;
};

/** Data item of a histogram */
export type HistogramItem = {
  __typename?: 'HistogramItem';
  answers?: Maybe<Scalars['Int']['output']>;
  group?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** Types of horizontal alignment */
export enum HorizontalAlign {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT'
}

/** Base options common for all question mutations */
export type ImageChoiceQuestionInput = {
  choices: Array<InputMaybe<ImageChoicesInput>>;
  chosenAnswerCount?: InputMaybe<AllowedChosenAnswerCountInput>;
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  isMultipleAnswer: Scalars['Boolean']['input'];
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

export type ImageChoicesInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['ImageUpload']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** Question answered by ordering set of items from most to least important */
export type ImportanceQuestion = ContentItem & QuestionInterface & {
  __typename?: 'ImportanceQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  rows: Array<PresetTextAnswer>;
  text: Scalars['String']['output'];
  video?: Maybe<Video>;
};

/** Result of ordering by importance question */
export type ImportanceQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'ImportanceQuestionAggregate';
  breakdown: Array<ImportanceQuestionBreakdownItem>;
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
};

/** Single item of a result of ordering by importance question */
export type ImportanceQuestionBreakdownItem = {
  __typename?: 'ImportanceQuestionBreakdownItem';
  averagePlace: Scalars['Float']['output'];
  averagePoints: Scalars['Float']['output'];
  item: PresetTextAnswer;
  points: Scalars['Float']['output'];
};

export type InAppContentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InAppEmail = {
  __typename?: 'InAppEmail';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export type IncreaseSeatsResult = {
  __typename?: 'IncreaseSeatsResult';
  currency: Scalars['String']['output'];
  price: Scalars['Float']['output'];
};

export type IndexingAllowedInput = {
  id: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};

/** Selected choice with possible user supplied text fo open answer choices */
export type IndividualChoiceAnswer = {
  __typename?: 'IndividualChoiceAnswer';
  choice: Choice;
  ownText?: Maybe<Scalars['String']['output']>;
};

/** Answer to one question */
export type IndividualQuestionAnswer = {
  __typename?: 'IndividualQuestionAnswer';
  answer?: Maybe<AllAnswerData>;
  questionId: Scalars['Int']['output'];
};

export type IntegrationCloudStorage = {
  __typename?: 'IntegrationCloudStorage';
  avatar?: Maybe<Scalars['Url']['output']>;
  email: Scalars['String']['output'];
  folderNames: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  provider: IntegrationEnum;
};

export enum IntegrationEnum {
  /** Backups to Dropbox */
  Dropbox = 'dropbox',
  /** Backups to Google Drive */
  GoogleDrive = 'googleDrive',
  /** Export to Google Sheets */
  GoogleSheets = 'googleSheets',
  /** Backups to Google OneDrive */
  Onedrive = 'onedrive'
}

export type IntegrationGoogle = {
  __typename?: 'IntegrationGoogle';
  avatar?: Maybe<Scalars['Url']['output']>;
  email: Scalars['String']['output'];
  folderNames: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  provider: IntegrationEnum;
  sheetNames: Array<Scalars['String']['output']>;
};

export type IntegrationSettings = {
  __typename?: 'IntegrationSettings';
  activeSince?: Maybe<Scalars['DateTime']['output']>;
  folderName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integration: IntegrationCloudStorage;
  /** time in 24-hour HH:MM format */
  syncTime?: Maybe<Scalars['String']['output']>;
};

export type IntegrationSettingsSheets = {
  __typename?: 'IntegrationSettingsSheets';
  activeSince?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integration: IntegrationCloudStorage;
  url?: Maybe<Scalars['String']['output']>;
};

export type InterfaceSettings = {
  __typename?: 'InterfaceSettings';
  leftBarAnalyticsCollapsed: Scalars['Boolean']['output'];
  leftBarBuilderCollapsed: Scalars['Boolean']['output'];
  leftBarSettingsCollapsed: Scalars['Boolean']['output'];
};

/** Survey's intro page (shown when initially when respondent visits the survey) */
export type IntroPage = {
  __typename?: 'IntroPage';
  /** Is the page shown to respondents (true) or not (false) */
  active: Scalars['Boolean']['output'];
  /** Content below the title (HTML formatted, containing only basic formatting - paragrapgs, alignment, bold, italic, links, pictures) */
  content?: Maybe<Scalars['String']['output']>;
  /** Logo associated with the survey */
  logo?: Maybe<Logo>;
  /** Text of the "Start survey" button */
  startButton?: Maybe<Scalars['String']['output']>;
  /** Title of a page */
  title: Scalars['String']['output'];
};

export type IntroPageMutationInput = {
  /** Is the page shown to respondents (true) or not (false) */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** content below the title (HTML basic formatting - paragraphs, alignment, bold, italic, links, pictures) */
  content?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['LogoUpload']['input']>;
  removeLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Text of the "Start survey" button */
  startButton?: InputMaybe<Scalars['String']['input']>;
  /** title of a page, so far same as survey title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** string identifier used in the app */
  uid: Scalars['String']['input'];
};

/** Defines when the invitation should start to be sent out */
export type InvitationScheduleInput = {
  delay?: InputMaybe<DelayInput>;
  sendNow: Scalars['Boolean']['input'];
  timestamp?: InputMaybe<ZonedTimestampInput>;
};

export type InvitationStatus = {
  __typename?: 'InvitationStatus';
  /** Number of messages that have registered a clickthrough to the survey */
  clicks: Scalars['Int']['output'];
  /** Number of messages that were delivered */
  delivers: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** Invitation is scheduled to send later */
  isWaiting?: Maybe<Scalars['Boolean']['output']>;
  /** Number of messages that were opened */
  opens: Scalars['Int']['output'];
  recipientConnection?: Maybe<RecipientStatusConnection>;
  /** List of recipients of an invitation for the survey, paged */
  recipientOffsetConnection?: Maybe<RecipientStatusConnection>;
  /** @deprecated use recipientConnection instead */
  recipients?: Maybe<RecipientStatusConnection>;
  /** Number of contacts that were passed to mail delivery */
  recipientsDispatched: Scalars['Int']['output'];
  /** Number of contacts to invite to the survey in this batch */
  recipientsTotal: Scalars['Int']['output'];
  /** Date and time when the reminders are to be sent */
  reminderAt?: Maybe<ZonedTimestamp>;
  /** Date and time when the invitations are to be sent */
  scheduledAt: ZonedTimestamp;
  /** Number of succesfully completed surveys for the batch */
  submits: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};


export type InvitationStatusRecipientConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InvitationStatusRecipientOffsetConnectionArgs = {
  page?: InputMaybe<Scalars['PositiveNumber']['input']>;
  perPage: Scalars['PositiveNumber']['input'];
  withStatusEither?: InputMaybe<Array<DeliveryStatus>>;
};


export type InvitationStatusRecipientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of items. */
export type InvitationStatusConnection = {
  __typename?: 'InvitationStatusConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<InvitationStatusEdge>>>;
  monthlyLimit: Scalars['Int']['output'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  sentThisMonth: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvitationStatusEdge = {
  __typename?: 'InvitationStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InvitationStatus>;
};

export type InvitedByMember = {
  __typename?: 'InvitedByMember';
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Represents one invoice issued to the customer */
export type Invoice = {
  __typename?: 'Invoice';
  currency: Scalars['String']['output'];
  /** The invoice is generated in Survio and needs to be downloaded using local URL with proper authorization with user's token */
  internal: Scalars['Boolean']['output'];
  issued: Scalars['Date']['output'];
  /** Link to download the invoice */
  link: Scalars['Url']['output'];
  /** Premium service name */
  name: Scalars['String']['output'];
  number: Scalars['ID']['output'];
  /** Billing period in months */
  period?: Maybe<Scalars['PositiveNumber']['output']>;
  price: Scalars['Float']['output'];
};

export type InvoiceData = {
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  /** ISO 3166-1 2-code, e.x.:CZ */
  countryCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  idNumber: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  period: ProformaSubscriptionPeriod;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Plan identifier (sysid) */
  plan: Scalars['String']['input'];
  street: Scalars['String']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type IpProtectionIpChangeInput = {
  /** query string key of the parameter (ie. appears in survey URL - ...?name=...) */
  address: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type IpProtectionIpInput = {
  /** query string key of the parameter (ie. appears in survey URL - ...?name=...) */
  address: Scalars['String']['input'];
};

/** new setting for recognized URL parameters */
export type IpProtectionSettingsInput = {
  active: Scalars['Boolean']['input'];
  add?: InputMaybe<IpProtectionIpInput>;
  change?: InputMaybe<IpProtectionIpChangeInput>;
  delete?: InputMaybe<Scalars['Int']['input']>;
};

/** List of IPs allowed to access the survey when active */
export type IpRestriction = {
  __typename?: 'IpRestriction';
  active: Scalars['Boolean']['output'];
  list: Array<AllowedIp>;
};

export type KeepAutoRenewalResult = {
  __typename?: 'KeepAutoRenewalResult';
  eligibleForRenewalDiscount: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Choice question layout enum */
export enum LayoutEnum {
  /** Choices next to each other */
  Col = 'COL',
  /** Choices under each other */
  Row = 'ROW',
  /** Choices in select box. !-- CAUTION: not possible with MULTI_CHOICE --! */
  Select = 'SELECT'
}

/** Question answered by writing the answer in the text field */
export type LocationQuestion = ContentItem & QuestionInterface & {
  __typename?: 'LocationQuestion';
  answerLengthLimit: Scalars['Int']['output'];
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  /** @deprecated probably not used, will be removed */
  subtype: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textCollectionId: Scalars['String']['output'];
  type: TextQuestionEnum;
  video?: Maybe<Video>;
};

/** Login page configuration */
export type LoginPage = {
  __typename?: 'LoginPage';
  enable: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  settings?: Maybe<Scalars['Json']['output']>;
  settingsDefined?: Maybe<Scalars['Json']['output']>;
  url: Scalars['String']['output'];
};

export type LoginPageInput = {
  enable: Scalars['Boolean']['input'];
  settings?: InputMaybe<Scalars['Json']['input']>;
};

/** Activity event captured on team account login */
export type LoginTheTeamAccount = ActivityInterface & {
  __typename?: 'LoginTheTeamAccount';
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type Logo = {
  __typename?: 'Logo';
  /** Height of the logo */
  h: Scalars['Int']['output'];
  /** Horizontal offset from the left side of a space dedicated for the logo */
  offsetX: Scalars['Int']['output'];
  /** Vertical offset from the top side of a space dedicated for the logo */
  offsetY: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  /** Width of the logo */
  w: Scalars['Int']['output'];
};

export type MatrixCollectionMapping = {
  __typename?: 'MatrixCollectionMapping';
  colId?: Maybe<Scalars['Int']['output']>;
  collectionId: Scalars['Int']['output'];
  rowId: Scalars['Int']['output'];
};

export type MatrixInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ordinal: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

/** One row or column of a matrix question */
export type MatrixItem = {
  __typename?: 'MatrixItem';
  id: Scalars['Int']['output'];
  ordinal: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

/** Grid of several rows which shares set of choices or grid of text fields */
export type MatrixQuestion = ContentItem & QuestionInterface & {
  __typename?: 'MatrixQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  collectionMapping?: Maybe<Array<MatrixCollectionMapping>>;
  /** Columns of the matrix */
  cols: Array<MatrixItem>;
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isEditedLimit: Scalars['Boolean']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  /** Maximum number of choices in a row that the respondents can pick */
  maximumAnswers?: Maybe<Scalars['Int']['output']>;
  /** Minimum number of choices in a row that the respondents needs to pick */
  minimumAnswers: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  /** Rows of the matrix */
  rows: Array<MatrixItem>;
  text: Scalars['String']['output'];
  /** Type of the matrix */
  type: MatrixQuestionEnum;
  video?: Maybe<Video>;
};

/** Result of a choice matrix question */
export type MatrixQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'MatrixQuestionAggregate';
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
  rowResults: Array<MatrixRowResult>;
};

/** Question types */
export enum MatrixQuestionEnum {
  /** Multi choice matrix question */
  MatrixMultiChoice = 'MATRIX_MULTI_CHOICE',
  /** Single choices matrix question */
  MatrixSingleChoice = 'MATRIX_SINGLE_CHOICE',
  /** Text fields in matrix question */
  MatrixText = 'MATRIX_TEXT'
}

/** Base options common for all question mutations */
export type MatrixQuestionInput = {
  chosenAnswerCount?: InputMaybe<AllowedChosenAnswerCountInput>;
  cols: Array<InputMaybe<MatrixInput>>;
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  rows: Array<InputMaybe<MatrixInput>>;
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  type: MatrixQuestionEnum;
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** MatrixRowResult */
export type MatrixRowResult = {
  __typename?: 'MatrixRowResult';
  breakdown: Array<BreakdownItem>;
  row?: Maybe<PresetTextAnswer>;
};

export type Member = {
  __typename?: 'Member';
  email?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<TeamMemberRole>;
  state?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

/** A connection to a list of items. */
export type MemberConnection = {
  __typename?: 'MemberConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<MemberEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Activity event captured on member deactivation */
export type MemberDeactivation = ActivityInterface & {
  __typename?: 'MemberDeactivation';
  data?: Maybe<MemberStateChangeData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

/** An edge in a connection. */
export type MemberEdge = {
  __typename?: 'MemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Member>;
};

export type MemberOffsetConnectionInput = {
  orderByName?: InputMaybe<SortDirection>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  teamMemberRoleFilters?: InputMaybe<Array<InputMaybe<TeamMemberRole>>>;
  teamMemberStatusFilters?: InputMaybe<Array<InputMaybe<MemberState>>>;
};

/** Activity event captured on member reactivation */
export type MemberReactivation = ActivityInterface & {
  __typename?: 'MemberReactivation';
  data?: Maybe<MemberStateChangeData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

/** status of member */
export enum MemberState {
  /** Is part of the team */
  Active = 'ACTIVE',
  /** User was removed from team */
  Deactivated = 'DEACTIVATED',
  /** Being delivered */
  Pending = 'PENDING',
  /** Invitation is delivered */
  Sent = 'SENT'
}

export type MemberStateChangeData = {
  __typename?: 'MemberStateChangeData';
  /** Email of member with changed state */
  email: Scalars['String']['output'];
  /** Name of member with changed state */
  name?: Maybe<Scalars['String']['output']>;
};

export type MigrationResult = {
  __typename?: 'MigrationResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MovingMemberPerson = {
  __typename?: 'MovingMemberPerson';
  /** Member's email */
  email?: Maybe<Scalars['String']['output']>;
  /** Member's full name */
  name?: Maybe<Scalars['String']['output']>;
};

/** Activity event captured when moving member's surveys to another member */
export type MovingMemberSurveys = ActivityInterface & {
  __typename?: 'MovingMemberSurveys';
  data?: Maybe<MovingMemberSurveysData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type MovingMemberSurveysData = {
  __typename?: 'MovingMemberSurveysData';
  from?: Maybe<MovingMemberPerson>;
  /** Moved surveys */
  surveyNames?: Maybe<Array<Scalars['String']['output']>>;
  to?: Maybe<MovingMemberPerson>;
};

/** Changes to current paid premium services */
export type Mutation = {
  __typename?: 'Mutation';
  NPSQuestion: NetPromoterQuestion;
  acknowledgePricingChange: MutationResult;
  acknowledgeUnlockedService: Scalars['Boolean']['output'];
  activateSurvey: Survey;
  addAnswerFilter: Array<Maybe<AnswersFilter>>;
  addCompletenessFilter: CompletenessFilter;
  addDatePeriodFilter: DatePeriodFilter;
  addResponseDurationFilter: Array<ResponseDurationFilter>;
  addSourceFilter: Array<SourceFilter>;
  /** Adds filter by certain answers to dashboard */
  addSurveyDashboardAnswerFilter: Array<AnswersFilter>;
  /** Adds filter by date period to dashboard */
  addSurveyDashboardDatePeriodFilter: SurveyDashboardDatePeriodFilter;
  /** Edit survey dashboard properties */
  addSurveyDashboardQuestions?: Maybe<Array<DashboardTileTypes>>;
  /** Adds filter by response duration to dashboard */
  addSurveyDashboardResponseDurationFilter: Array<ResponseDurationFilter>;
  /** Adds filter by responses source to dashboard */
  addSurveyDashboardSourceFilter: Array<SourceFilter>;
  advanceInvoice: PendingProforma;
  answerSegmentationSurvey: AnswerSegmentationSurveyResponse;
  cancelAutorenewal: Scalars['Boolean']['output'];
  cancelTeamInvitation: MutationResult;
  changeIntroPage: EdgePageMutationResult;
  /** @deprecated Use editMember instead */
  changeMemberRole: MutationResult;
  changePassword: Scalars['Boolean']['output'];
  changeSurveySettings: SurveySettingsUpdateResult;
  changeSurveyTheme?: Maybe<ThemeMutations>;
  changeSurveyTitle: EdgePageMutationResult;
  changeThanksPage: EdgePageMutationResult;
  choiceQuestion: ChoiceQuestion;
  closeInAppContent: Scalars['Boolean']['output'];
  closeInAppEmail: Scalars['Boolean']['output'];
  connectGoogleAnalytics: Tracking;
  connectIntegration: IntegrationSettings;
  connectSheetsIntegration: IntegrationSettingsSheets;
  copyItem?: Maybe<CopyMutationResult>;
  copySurvey: Survey;
  /** Copy existing survey dashboard */
  copySurveyDashboard: SurveyDashboard;
  copyTemplate: Survey;
  createBlankSurvey: Survey;
  createDemoSurvey: Survey;
  createPage: SurveyPage;
  /** Create new survey dashboard */
  createSurveyDashboard: SurveyDashboard;
  createSurveyFromPrompt: Survey;
  createTeam: Team;
  createWorkspace: Workspace;
  customSurveyLanguage: CustomSurveyLanguage;
  customizeUrl: CustomUrl;
  deactivateSurvey: Survey;
  deleteCustomSurveyLanguage: Scalars['Boolean']['output'];
  deleteInvitation?: Maybe<MutationResult>;
  deleteItem: Scalars['Boolean']['output'];
  deleteSurvey: CardConnection;
  /** Delete survey dashboard with certain id */
  deleteSurveyDashboard: MutationResult;
  deleteSurveys: MutationResult;
  deleteWorkspace: MutationResult;
  discardResponses: Scalars['Boolean']['output'];
  disconnectGoogleAnalytics: Scalars['Boolean']['output'];
  disconnectIntegration: Scalars['Boolean']['output'];
  disconnectSheetsIntegration: Scalars['Boolean']['output'];
  dismissExtendedYearlyBusinessPromo: Scalars['Boolean']['output'];
  dismissMessage: Scalars['Boolean']['output'];
  downloadDashboardReport: MutationResult;
  editMember?: Maybe<Member>;
  /** Edit survey dashboard properties */
  editSurveyDashboard: SurveyDashboard;
  emailActivation: Scalars['Boolean']['output'];
  emojiQuestion: EmojiQuestionOutput;
  imageChoiceQuestion: ChoiceQuestion;
  increaseSeats: Scalars['Boolean']['output'];
  keepAutoRenewal: KeepAutoRenewalResult;
  matrixQuestion: MatrixQuestion;
  memberDeactivation: MutationResult;
  memberReactivation: MutationResult;
  migrateToExperiment: MigrationResult;
  /**
   * Moves survey content item either to a specified position on a given page (or same page if new page is not given).
   *                     Return value indicates whether the item actually moved within the content (returns false if no change was necessary).
   *
   */
  moveBlock: Scalars['Boolean']['output'];
  movePage: SurveyPage;
  moveSurveyToWorkspace: MutationResult;
  newInvitation?: Maybe<InvitationStatus>;
  ordinalQuestion: ImportanceQuestion;
  previewInvitation: Scalars['Boolean']['output'];
  processVerificationCode: MutationResult;
  ratingQuestion: RatingQuestion;
  readNotification: MutationResult;
  /** Removes all dashboard filters */
  removeAllSurveyDashboardFilters: MutationResult;
  removeFilters: Scalars['Boolean']['output'];
  removePage: MutationResult;
  /** Removes dashboard filter */
  removeSurveyDashboardFilter: MutationResult;
  /** Add questions to existing survey dashboard */
  removeSurveyDashboardTile: MutationResult;
  removeUser: Scalars['Boolean']['output'];
  /** Sends e-mail request to increase response limit */
  requestCustomPlan: MutationResult;
  /** Sends e-mail request to change number of seats in team */
  requestTeamSeats: MutationResult;
  resendTeamInvitation: MutationResult;
  restoreAutorenewal: Scalars['Boolean']['output'];
  saveShareResultsSettings: ShareResultSettings;
  scrubResponses: Scalars['Boolean']['output'];
  semanticQuestion: SemanticDifferentialQuestion;
  /** Sends dashboard sharing link to up to 10 emails */
  sendDashboardSharingURL: MutationResult;
  sendEmailVerification: Scalars['Boolean']['output'];
  sendLoginChangeCode: MutationResult;
  /** Send preview of email report to user's email */
  sendPreviewDashboardReport: MutationResult;
  sendTeamInvitation: SendTeamInvitationResult;
  /** Create/update chart theme */
  setChartTheme: ChartTheme;
  /** Set automated dashboard reports to given emails */
  setDashboardReport?: Maybe<AutomatedReports>;
  /** Enable or disable of dashboard sharing */
  setDashboardSharing: DashboardSharing;
  /** Set chart theme of dashboard tile */
  setDashboardTileChartSettings?: Maybe<ChartSettings>;
  /** Set default response storage for user */
  setDefaultResponseStorage?: Maybe<ResponseStorage>;
  setIndexingAllowed: Scalars['Boolean']['output'];
  setLogic: Scalars['Boolean']['output'];
  setLoginPage: MutationResult;
  setMySurveysViewStyle: MutationResult;
  setQuestionAnalyticsPreferences: MutationResult;
  /** Set analytics preferences of dashboard question */
  setSurveyDashboardQuestionAnalyticsPreferences: Scalars['Json']['output'];
  setSurveyNote: Survey;
  /** Survey public dashboard */
  setSurveyPublicDashboard: MutationResult;
  /** Set response storage for survey */
  setSurveyResponseStorage?: Maybe<ResponseStorage>;
  setSurveyTitle: Survey;
  setTeamLanguage: MutationResult;
  setTour: Scalars['Boolean']['output'];
  splitPointsQuestion: DividePointsQuestion;
  startDashboardReport: ReportStartResponse;
  startExport: ExportStartResponse;
  startReport: ReportStartResponse;
  stripeUpgrade: StripeUpgradeResult;
  teamInvitationAccept: MutationResult;
  textBlock?: Maybe<TextBlock>;
  textQuestion: TextQuestion;
  transferSurveys: MutationResult;
  updateExperiment: Scalars['Boolean']['output'];
  updateInterface: InterfaceSettings;
  updateSurveySharingMetadata?: Maybe<SurveySharingMetadata>;
  updateTeam: Team;
  updateUser?: Maybe<User>;
  updateWorkspace: Workspace;
  upgradeService: Scalars['Boolean']['output'];
  /** Upload report of the dashboard */
  uploadDashboardReport: Scalars['String']['output'];
  uploadImage: SurveyTextImageUploadResult;
};


/** Changes to current paid premium services */
export type MutationNpsQuestionArgs = {
  questionData: NpsQuestionInput;
};


/** Changes to current paid premium services */
export type MutationAcknowledgePricingChangeArgs = {
  lockPrice?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
};


/** Changes to current paid premium services */
export type MutationActivateSurveyArgs = {
  input: SurveyIdentificationInput;
};


/** Changes to current paid premium services */
export type MutationAddAnswerFilterArgs = {
  answers: Array<AnswerFilterInput>;
  question: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationAddCompletenessFilterArgs = {
  sysid: CompletenessEnum;
  uid: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationAddDatePeriodFilterArgs = {
  from: Scalars['DateTime']['input'];
  till: Scalars['DateTime']['input'];
  uid: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationAddResponseDurationFilterArgs = {
  sysid?: InputMaybe<DurationEnum>;
  sysidList?: InputMaybe<Array<DurationEnum>>;
  uid: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationAddSourceFilterArgs = {
  source?: InputMaybe<VisitSource>;
  sourceList?: InputMaybe<Array<VisitSource>>;
  uid: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationAddSurveyDashboardAnswerFilterArgs = {
  input: SurveyDashboardAnswerFilterInput;
};


/** Changes to current paid premium services */
export type MutationAddSurveyDashboardDatePeriodFilterArgs = {
  input: SurveyDashboardDatePeriodFilterInput;
};


/** Changes to current paid premium services */
export type MutationAddSurveyDashboardQuestionsArgs = {
  input: AddSurveyDashboardQuestionsInput;
};


/** Changes to current paid premium services */
export type MutationAddSurveyDashboardResponseDurationFilterArgs = {
  input: SurveyDashboardResponseDurationFilterInput;
};


/** Changes to current paid premium services */
export type MutationAddSurveyDashboardSourceFilterArgs = {
  input: SurveyDashboardSourceFilterInput;
};


/** Changes to current paid premium services */
export type MutationAdvanceInvoiceArgs = {
  data: InvoiceData;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Changes to current paid premium services */
export type MutationAnswerSegmentationSurveyArgs = {
  input?: InputMaybe<AnswerSegmentationSurveyInput>;
};


/** Changes to current paid premium services */
export type MutationCancelAutorenewalArgs = {
  answers?: InputMaybe<Array<Scalars['Int']['input']>>;
  otherAnswer?: InputMaybe<Scalars['String']['input']>;
};


/** Changes to current paid premium services */
export type MutationCancelTeamInvitationArgs = {
  memberId: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationChangeIntroPageArgs = {
  input: IntroPageMutationInput;
};


/** Changes to current paid premium services */
export type MutationChangeMemberRoleArgs = {
  memberId: Scalars['ID']['input'];
  role: TeamMemberRole;
};


/** Changes to current paid premium services */
export type MutationChangePasswordArgs = {
  confirmation: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationChangeSurveySettingsArgs = {
  input: SurveySettingsUpdateInput;
};


/** Changes to current paid premium services */
export type MutationChangeSurveyTitleArgs = {
  title: Scalars['String']['input'];
  uid: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationChangeThanksPageArgs = {
  input: ThanksPageMutationInput;
};


/** Changes to current paid premium services */
export type MutationChoiceQuestionArgs = {
  questionData: ChoiceQuestionInput;
};


/** Changes to current paid premium services */
export type MutationCloseInAppContentArgs = {
  input: InAppContentInput;
};


/** Changes to current paid premium services */
export type MutationCloseInAppEmailArgs = {
  input: CloseInAppEmailInput;
};


/** Changes to current paid premium services */
export type MutationConnectGoogleAnalyticsArgs = {
  code: Scalars['String']['input'];
  uid: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationConnectIntegrationArgs = {
  folderName: Scalars['String']['input'];
  integration: Scalars['ID']['input'];
  syncAt?: InputMaybe<Scalars['String']['input']>;
  uid: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationConnectSheetsIntegrationArgs = {
  fileName: Scalars['String']['input'];
  integration: Scalars['ID']['input'];
  uid: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationCopyItemArgs = {
  id: Scalars['Int']['input'];
};


/** Changes to current paid premium services */
export type MutationCopySurveyArgs = {
  input?: InputMaybe<CopySurveyInput>;
};


/** Changes to current paid premium services */
export type MutationCopySurveyDashboardArgs = {
  input: CopySurveyDashboardInput;
};


/** Changes to current paid premium services */
export type MutationCopyTemplateArgs = {
  input?: InputMaybe<CopySurveyInput>;
};


/** Changes to current paid premium services */
export type MutationCreateBlankSurveyArgs = {
  motiveId?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


/** Changes to current paid premium services */
export type MutationCreatePageArgs = {
  hash?: InputMaybe<Scalars['ID']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  surveyUID: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationCreateSurveyDashboardArgs = {
  input: CreateSurveyDashboardInput;
};


/** Changes to current paid premium services */
export type MutationCreateSurveyFromPromptArgs = {
  prompt: Scalars['String']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


/** Changes to current paid premium services */
export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


/** Changes to current paid premium services */
export type MutationCreateWorkspaceArgs = {
  input: WorkspaceInput;
};


/** Changes to current paid premium services */
export type MutationCustomSurveyLanguageArgs = {
  input: CustomSurveyLanguageInput;
};


/** Changes to current paid premium services */
export type MutationCustomizeUrlArgs = {
  input: CustomUrlInput;
};


/** Changes to current paid premium services */
export type MutationDeactivateSurveyArgs = {
  input: SurveyIdentificationInput;
};


/** Changes to current paid premium services */
export type MutationDeleteCustomSurveyLanguageArgs = {
  input: Scalars['Int']['input'];
};


/** Changes to current paid premium services */
export type MutationDeleteInvitationArgs = {
  input: SurveyInvitationDeleteRequest;
};


/** Changes to current paid premium services */
export type MutationDeleteItemArgs = {
  id: Scalars['Int']['input'];
};


/** Changes to current paid premium services */
export type MutationDeleteSurveyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  uid: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationDeleteSurveyDashboardArgs = {
  id: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationDeleteSurveysArgs = {
  memberId?: InputMaybe<Scalars['ID']['input']>;
  removeAll?: InputMaybe<Scalars['Boolean']['input']>;
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


/** Changes to current paid premium services */
export type MutationDeleteWorkspaceArgs = {
  action: WorkspaceRemoveAction;
  id: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationDiscardResponsesArgs = {
  input?: InputMaybe<ExcludeResponsesInput>;
};


/** Changes to current paid premium services */
export type MutationDisconnectGoogleAnalyticsArgs = {
  uid: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationDisconnectIntegrationArgs = {
  integration: Scalars['ID']['input'];
  uid: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationDisconnectSheetsIntegrationArgs = {
  integration: Scalars['ID']['input'];
  uid: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationDismissMessageArgs = {
  id: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationDownloadDashboardReportArgs = {
  input: DownloadDashboardReportInput;
};


/** Changes to current paid premium services */
export type MutationEditMemberArgs = {
  input: EditMemberInput;
};


/** Changes to current paid premium services */
export type MutationEditSurveyDashboardArgs = {
  input: EditSurveyDashboardInput;
};


/** Changes to current paid premium services */
export type MutationEmailActivationArgs = {
  activationId: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationEmojiQuestionArgs = {
  questionData: EmojiQuestionInput;
};


/** Changes to current paid premium services */
export type MutationImageChoiceQuestionArgs = {
  questionData: ImageChoiceQuestionInput;
};


/** Changes to current paid premium services */
export type MutationIncreaseSeatsArgs = {
  quantity: Scalars['Int']['input'];
};


/** Changes to current paid premium services */
export type MutationMatrixQuestionArgs = {
  questionData: MatrixQuestionInput;
};


/** Changes to current paid premium services */
export type MutationMemberDeactivationArgs = {
  memberId: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationMemberReactivationArgs = {
  memberId: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationMigrateToExperimentArgs = {
  experiment: Scalars['String']['input'];
  variant: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationMoveBlockArgs = {
  id: Scalars['ID']['input'];
  newOrdinal: Scalars['Int']['input'];
  newPage?: InputMaybe<Scalars['ID']['input']>;
};


/** Changes to current paid premium services */
export type MutationMovePageArgs = {
  newPosition: Scalars['Int']['input'];
  pageId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};


/** Changes to current paid premium services */
export type MutationMoveSurveyToWorkspaceArgs = {
  surveyId: Scalars['ID']['input'];
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


/** Changes to current paid premium services */
export type MutationNewInvitationArgs = {
  input: SurveyInvitationRequest;
};


/** Changes to current paid premium services */
export type MutationOrdinalQuestionArgs = {
  questionData: OrdinalQuestionInput;
};


/** Changes to current paid premium services */
export type MutationPreviewInvitationArgs = {
  input: PreviewInvitationRequest;
};


/** Changes to current paid premium services */
export type MutationProcessVerificationCodeArgs = {
  code: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationRatingQuestionArgs = {
  questionData: RatingQuestionInput;
};


/** Changes to current paid premium services */
export type MutationReadNotificationArgs = {
  id: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationRemoveAllSurveyDashboardFiltersArgs = {
  dashboardId: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationRemoveFiltersArgs = {
  id: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationRemovePageArgs = {
  pageId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};


/** Changes to current paid premium services */
export type MutationRemoveSurveyDashboardFilterArgs = {
  id: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationRemoveSurveyDashboardTileArgs = {
  id: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationRemoveUserArgs = {
  password: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationRequestCustomPlanArgs = {
  input: RequestCustomPlanInput;
};


/** Changes to current paid premium services */
export type MutationRequestTeamSeatsArgs = {
  input: RequestTeamSeatsInput;
};


/** Changes to current paid premium services */
export type MutationResendTeamInvitationArgs = {
  memberId: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationSaveShareResultsSettingsArgs = {
  shareResultsSettings: ShareResultSettingsInput;
  uid: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationScrubResponsesArgs = {
  input?: InputMaybe<ScrubResponsesInput>;
};


/** Changes to current paid premium services */
export type MutationSemanticQuestionArgs = {
  questionData: SemanticDiffQuestionInput;
};


/** Changes to current paid premium services */
export type MutationSendDashboardSharingUrlArgs = {
  input: SendDashboardSharingUrlInput;
};


/** Changes to current paid premium services */
export type MutationSendLoginChangeCodeArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};


/** Changes to current paid premium services */
export type MutationSendPreviewDashboardReportArgs = {
  input: SendPreviewDashboardReportInput;
};


/** Changes to current paid premium services */
export type MutationSendTeamInvitationArgs = {
  input: SendTeamInvitationInput;
};


/** Changes to current paid premium services */
export type MutationSetChartThemeArgs = {
  input: ChartThemeInput;
};


/** Changes to current paid premium services */
export type MutationSetDashboardReportArgs = {
  input?: InputMaybe<SetDashboardReportInput>;
};


/** Changes to current paid premium services */
export type MutationSetDashboardSharingArgs = {
  input: DashboardSharingInput;
};


/** Changes to current paid premium services */
export type MutationSetDashboardTileChartSettingsArgs = {
  id: Scalars['ID']['input'];
  input: ChartSettingsInput;
};


/** Changes to current paid premium services */
export type MutationSetDefaultResponseStorageArgs = {
  input: SetDefaultResponseStorageInput;
};


/** Changes to current paid premium services */
export type MutationSetIndexingAllowedArgs = {
  input: IndexingAllowedInput;
};


/** Changes to current paid premium services */
export type MutationSetLogicArgs = {
  data: Array<InputMaybe<LogicRulesInput>>;
  questionId: Scalars['ID']['input'];
};


/** Changes to current paid premium services */
export type MutationSetLoginPageArgs = {
  input: LoginPageInput;
};


/** Changes to current paid premium services */
export type MutationSetMySurveysViewStyleArgs = {
  orderBy?: InputMaybe<MySurveysOrderByInput>;
  viewStyle?: InputMaybe<ViewStyle>;
};


/** Changes to current paid premium services */
export type MutationSetQuestionAnalyticsPreferencesArgs = {
  input?: InputMaybe<QuestionPreferencesInput>;
};


/** Changes to current paid premium services */
export type MutationSetSurveyDashboardQuestionAnalyticsPreferencesArgs = {
  input: SurveyDashboardQuestionAnalyticsPreferencesInput;
};


/** Changes to current paid premium services */
export type MutationSetSurveyNoteArgs = {
  input: SurveyTextInput;
};


/** Changes to current paid premium services */
export type MutationSetSurveyPublicDashboardArgs = {
  input: SetSurveyPublicDashboardInput;
};


/** Changes to current paid premium services */
export type MutationSetSurveyResponseStorageArgs = {
  input: SetSurveyResponseStorageInput;
};


/** Changes to current paid premium services */
export type MutationSetSurveyTitleArgs = {
  input: SurveyTextInput;
};


/** Changes to current paid premium services */
export type MutationSetTeamLanguageArgs = {
  language: Scalars['LanguageCode']['input'];
};


/** Changes to current paid premium services */
export type MutationSetTourArgs = {
  input: TourInput;
};


/** Changes to current paid premium services */
export type MutationSplitPointsQuestionArgs = {
  questionData: SplitPointsQuestionInput;
};


/** Changes to current paid premium services */
export type MutationStartDashboardReportArgs = {
  input: DashboardReportInput;
};


/** Changes to current paid premium services */
export type MutationStartExportArgs = {
  input: ExportInput;
};


/** Changes to current paid premium services */
export type MutationStartReportArgs = {
  input: ReportInput;
};


/** Changes to current paid premium services */
export type MutationStripeUpgradeArgs = {
  input: StripeUpgradeInput;
};


/** Changes to current paid premium services */
export type MutationTextBlockArgs = {
  data: TextBlockInput;
};


/** Changes to current paid premium services */
export type MutationTextQuestionArgs = {
  questionData: TextQuestionInput;
};


/** Changes to current paid premium services */
export type MutationTransferSurveysArgs = {
  memberId?: InputMaybe<Scalars['ID']['input']>;
  surveyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  targetMemberId?: InputMaybe<Scalars['ID']['input']>;
  transferAll?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Changes to current paid premium services */
export type MutationUpdateExperimentArgs = {
  input?: InputMaybe<UpdateExperimentInput>;
};


/** Changes to current paid premium services */
export type MutationUpdateInterfaceArgs = {
  input?: InputMaybe<UserInterfaceSettingsInput>;
};


/** Changes to current paid premium services */
export type MutationUpdateSurveySharingMetadataArgs = {
  input?: InputMaybe<SurveySharingMetadataInput>;
};


/** Changes to current paid premium services */
export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


/** Changes to current paid premium services */
export type MutationUpdateUserArgs = {
  changes: UserEditableInput;
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Changes to current paid premium services */
export type MutationUpdateWorkspaceArgs = {
  id: Scalars['ID']['input'];
  input: WorkspaceInput;
};


/** Changes to current paid premium services */
export type MutationUpgradeServiceArgs = {
  additionalSeatsCount?: InputMaybe<Scalars['Int']['input']>;
  extendedFirstPeriod?: InputMaybe<Scalars['Boolean']['input']>;
  freeMonths?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<PremiumServicePeriod>;
  saleSource?: InputMaybe<SaleSourceInput>;
  type?: InputMaybe<PremiumServiceType>;
};


/** Changes to current paid premium services */
export type MutationUploadDashboardReportArgs = {
  input: UploadDashboardReportInput;
};


/** Changes to current paid premium services */
export type MutationUploadImageArgs = {
  input: SurveyTextImageInput;
};

/** Status of the mutation request */
export type MutationResult = {
  __typename?: 'MutationResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MySurveysOrderBy = {
  __typename?: 'MySurveysOrderBy';
  orderByDirection: SortDirection;
  orderByKey: MySurveysOrderByKeys;
};

/** Input object that says what */
export type MySurveysOrderByInput = {
  orderByDirection: SortDirection;
  orderByKey: MySurveysOrderByKeys;
};

export enum MySurveysOrderByKeys {
  /** Surveys are ordered alphanumerically */
  Alphabetically = 'ALPHABETICALLY',
  /** Surveys are ordered by the date of creation */
  Creation = 'CREATION',
  /** Surveys are ordered by the date of last edition */
  LastEdited = 'LAST_EDITED'
}

/** Base options common for all question mutations */
export type NpsQuestionInput = {
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  leftText: Scalars['String']['input'];
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  rightText: Scalars['String']['input'];
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

export enum NeighborEnum {
  /** Next response */
  Next = 'NEXT',
  /** Previous response */
  Prev = 'PREV'
}

/** Question for evaluating Net Promoter Score of a product or service etc. */
export type NetPromoterQuestion = ContentItem & QuestionInterface & {
  __typename?: 'NetPromoterQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** text on the left side of the answer options (beside 0), ie. not likely to recommend, usally reading "not at all" */
  leftText: Scalars['String']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  npsCollectionId: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  /** text on the left side of the answer options (beside 10), ie. likely to recommend */
  rightText: Scalars['String']['output'];
  text: Scalars['String']['output'];
  video?: Maybe<Video>;
};

/**
 * Aggregate results for Net Promoter questions, contains both counts for individual answer options (0 - 10) and the 3 significant groups - detractors (answers 0 - 6), passive (answers 7 - 8), promoters (answers 9 - 10)
 *
 */
export type NetPromoterQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'NetPromoterQuestionAggregate';
  breakdown?: Maybe<Array<Maybe<BreakdownItem>>>;
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
  summary?: Maybe<NetPromoterSummary>;
};

/** Summary result of Net Promoter question including Net Promoter Score metric */
export type NetPromoterSummary = {
  __typename?: 'NetPromoterSummary';
  detractors: Scalars['Int']['output'];
  passive: Scalars['Int']['output'];
  promoters: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
};

export type NewParameterInput = {
  /** query string key of the parameter (ie. appears in survey URL - ...?name=...) */
  name: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export enum OrderType {
  /** Ascending order of values */
  Asc = 'ASC',
  /** Original order */
  Default = 'DEFAULT',
  /** Descending order of values */
  Desc = 'DESC'
}

export type OrdinalInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** Base options common for all question mutations */
export type OrdinalQuestionInput = {
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  rows: Array<InputMaybe<OrdinalInput>>;
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParameterChangeInput = {
  id: Scalars['Int']['input'];
  /** query string key of the parameter (ie. appears in survey URL - ...?name=...) */
  name: Scalars['String']['input'];
};

/** new setting for recognized URL parameters */
export type ParameterSettingsInput = {
  active: Scalars['Boolean']['input'];
  add?: InputMaybe<NewParameterInput>;
  change?: InputMaybe<ParameterChangeInput>;
  delete?: InputMaybe<Scalars['Int']['input']>;
};

/** Password protection settings */
export type PasswordProtection = {
  __typename?: 'PasswordProtection';
  active: Scalars['Boolean']['output'];
  password?: Maybe<Scalars['String']['output']>;
};

/** new setting for the submit redirect */
export type PasswordSettingsInput = {
  active: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type PendingProforma = {
  __typename?: 'PendingProforma';
  invoiceUrl: Scalars['String']['output'];
  number: Scalars['String']['output'];
};

/** Picture and its metadata */
export type Picture = {
  __typename?: 'Picture';
  h?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
  w?: Maybe<Scalars['Int']['output']>;
};

/** One row of the pivot table, with the total answers of the item broken down into counts for the items of the second question */
export type PivotBreakdownItem = {
  __typename?: 'PivotBreakdownItem';
  answer: TextAnswer;
  /** counts of secondary question answers for respondents who answered as specified in the field "answer" of this PivotBreakdownItem */
  breakdown?: Maybe<Array<BreakdownItem>>;
  /** total count of respondents of the first dimension, including unanswered in the second */
  count: Scalars['Int']['output'];
  /** count of respondents with no answer for the second question */
  unanswered: Scalars['Int']['output'];
};

/** Result of a pivot of two simple questions (designed for 2 single or possibly multipe choice questions) */
export type PivotData2D = {
  __typename?: 'PivotData2D';
  /** items are based on primary question/dimension of the pivot */
  breakdown?: Maybe<Array<PivotBreakdownItem>>;
  unansweredBreakdown?: Maybe<Array<BreakdownItem>>;
};

export type Plan = {
  __typename?: 'Plan';
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PoweredBySurvioButton = {
  __typename?: 'PoweredBySurvioButton';
  /** Whether the button should be shown in free version */
  free?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the button should be shown */
  shown?: Maybe<Scalars['Boolean']['output']>;
};

export enum PremiumServicePeriod {
  Monthly = 'MONTHLY',
  TwoYears = 'TWO_YEARS',
  Yearly = 'YEARLY'
}

export enum PremiumServiceType {
  /**
   * Business
   * @deprecated Renamed to STANDARD
   */
  Business = 'BUSINESS',
  /**
   * Elite
   * @deprecated Renamed to PLUS
   */
  Elite = 'ELITE',
  /** Enterprise */
  Enterprise = 'ENTERPRISE',
  /** Medium */
  Medium = 'MEDIUM',
  /** Mini */
  Mini = 'MINI',
  /**
   * Personal
   * @deprecated Renamed to MINI
   */
  Personal = 'PERSONAL',
  /** Plus */
  Plus = 'PLUS',
  /**
   * Small
   * @deprecated Renamed to STARTER
   */
  Small = 'SMALL',
  /** Standard */
  Standard = 'STANDARD',
  /** Starter */
  Starter = 'STARTER'
}

/** Predefined row answer */
export type PresetRowTextAnswer = {
  __typename?: 'PresetRowTextAnswer';
  id: Scalars['Int']['output'];
  ordinal: Scalars['Int']['output'];
  /** Filled if Multi-choice, Divide-points, Ordering question */
  rowCollectionId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

/** Predefined anwser, such as defined option for choiced questions etc. */
export type PresetTextAnswer = {
  __typename?: 'PresetTextAnswer';
  /** Filled if Multi-choice, Divide-points, Ordering question */
  collectionId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  ordinal: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

/** Settings for preview of the invitation */
export type PreviewInvitationRequest = {
  embeddedQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  message: SurveyInvitationMessageInput;
  uid: Scalars['String']['input'];
};

export type PreviousPremiumService = {
  __typename?: 'PreviousPremiumService';
  activeSince?: Maybe<Scalars['DateTime']['output']>;
  activeTill?: Maybe<Scalars['DateTime']['output']>;
  cardExpirationDate?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  period?: Maybe<Scalars['ServicePeriod']['output']>;
  plan: Scalars['String']['output'];
  responseCollectionLimit?: Maybe<Scalars['Int']['output']>;
  responseLimit?: Maybe<Scalars['Int']['output']>;
  seatsCount?: Maybe<Scalars['Int']['output']>;
};

export type PriceList = {
  __typename?: 'PriceList';
  /** @deprecated Use "standard" field */
  business: PricePlan;
  currencyCode: Scalars['String']['output'];
  /** @deprecated Use "plus" field */
  elite: PricePlan;
  enterprise: PriceProPlan;
  medium: PriceProPlan;
  mini: PricePlan;
  /** @deprecated Use "mini" field */
  personal: PricePlan;
  plus: PricePlan;
  /** @deprecated Use "starter" field */
  small: PriceProPlan;
  standard: PricePlan;
  starter: PriceProPlan;
};

export type PricePlan = {
  __typename?: 'PricePlan';
  currencyCode: Scalars['String']['output'];
  featureList: Array<Scalars['String']['output']>;
  features: Array<Scalars['String']['output']>;
  invitationLimit: Scalars['Int']['output'];
  monthlyAmount?: Maybe<Scalars['Float']['output']>;
  monthlyAmountWithVat?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  responseLimit: Scalars['Int']['output'];
  twoYearAmount?: Maybe<Scalars['Float']['output']>;
  twoYearAmountWithVat?: Maybe<Scalars['Float']['output']>;
  type: PremiumServiceType;
  yearlyAmount: Scalars['Float']['output'];
  yearlyAmountWithVat: Scalars['Float']['output'];
};

export type PriceProPlan = {
  __typename?: 'PriceProPlan';
  currencyCode: Scalars['String']['output'];
  featureList: Array<Scalars['String']['output']>;
  features: Array<Scalars['String']['output']>;
  invitationLimit: Scalars['Int']['output'];
  monthlyAmount?: Maybe<Scalars['Float']['output']>;
  monthlyAmountWithVat?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  responseLimit: Scalars['Int']['output'];
  seatsDefault: Scalars['Int']['output'];
  seatsMax?: Maybe<Scalars['Int']['output']>;
  twoYearAmount?: Maybe<Scalars['Float']['output']>;
  twoYearAmountWithVat?: Maybe<Scalars['Float']['output']>;
  twoYearSeatPrice?: Maybe<Scalars['Float']['output']>;
  type: PremiumServiceType;
  yearSeatPrice?: Maybe<Scalars['Float']['output']>;
  yearlyAmount: Scalars['Float']['output'];
  yearlyAmountWithVat: Scalars['Float']['output'];
};

export type PricingChange = {
  __typename?: 'PricingChange';
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>;
  emailedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Price before the change (monthly) */
  fromPrice?: Maybe<Scalars['Float']['output']>;
  /** Price after the change (monthly) */
  toPrice: Scalars['Float']['output'];
};

export enum PricingClientErrorEnum {
  CouponInvalid = 'COUPON_INVALID'
}

export enum ProformaSubscriptionPeriod {
  FiveYears = 'FIVE_YEARS',
  FourYears = 'FOUR_YEARS',
  ThreeYears = 'THREE_YEARS',
  TwoYears = 'TWO_YEARS',
  Yearly = 'YEARLY'
}

export type Progress = {
  __typename?: 'Progress';
  /** Export is finished */
  done: Scalars['Boolean']['output'];
  /** Progress in percent */
  percent: Scalars['Float']['output'];
  /** Number of rows that were processed so far */
  rows: Scalars['Int']['output'];
};

/** Response filter related queries */
export type Query = {
  __typename?: 'Query';
  /**
   * Fetch application strings with its translations
   * @deprecated use translationStrings instead
   */
  appStrings?: Maybe<Scalars['Json']['output']>;
  /**
   * Calculates pivot table data for 2 choiced questions, i.e. breakdowns of how many respondents chose a specific answers for both questions, for example how many chose option 1 for question 1 and option 2 from question 2 at the same time. The results are broken down according to primaryQuestion's answers first, and each primaryQuestion's answer option contains breakdown of secondaryQuestion's answers for respondents that chose given primaryQuestion's answer option.
   *
   * @deprecated moved to survey.choicedPivotData.
   */
  choicedPivotData?: Maybe<PivotData2D>;
  countryList?: Maybe<Array<Country>>;
  exportDone: Scalars['Boolean']['output'];
  /** Breakdown of how many vistors finished in what timeframe */
  filloutDurationStats?: Maybe<FilloutDurationAggregate>;
  /**
   * Breakdown of how many vistors reached which stage - just arrived or quit patway, submitted, submitted but were discarded as unsuitable...
   * @deprecated moved to survey.filloutStatusStats
   */
  filloutStatusStats?: Maybe<FilloutStatusAggregate>;
  /** Currently defined filters for the survey results */
  filters: Filters;
  /**
   * List of invitations for the survey, paged
   * @deprecated use survey.invitationConnection
   */
  invitationListPage?: Maybe<InvitationStatusConnection>;
  /**
   * List of recipients of an invitation for the survey, paged
   * @deprecated use survey.invitation.recipientOffsetConnection instead
   */
  invitationRecipientListPage?: Maybe<RecipientStatusConnection>;
  /**
   * List of invitations for the survey
   * @deprecated moved to survey.invitationConnection
   */
  invitations?: Maybe<InvitationStatusConnection>;
  /** Last used or default invitation message */
  lastInvitationMessage?: Maybe<SurveyInvitationMessage>;
  /** Returns current pricelist of survio packages */
  priceList: PriceList;
  /** @deprecated moved to survey.question */
  question?: Maybe<QuestionInterface>;
  /**
   * Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question.
   * @deprecated moved to survey.question.results
   */
  questionAggregate?: Maybe<QuestionAggregateInterface>;
  /**
   * List of questions in a survey
   * @deprecated moved to survey.questions
   */
  questionList?: Maybe<Array<QuestionInterface>>;
  reportDone: Scalars['Boolean']['output'];
  /**
   * Respondent source statistics
   * @deprecated moved to survey.respondentSources
   */
  respondentSources?: Maybe<RespondentSourcesAggregate>;
  /** @deprecated moved to survey.response */
  response?: Maybe<ResponseBrowsingInfo>;
  /** List of individual responses for the survey */
  responsePage?: Maybe<ResponseConnection>;
  /** List of ResponseStorage */
  responseStorageList?: Maybe<Array<Maybe<ResponseStorage>>>;
  /**
   * list of individual responses for the survey
   * @deprecated moved to survey.responseConnection
   */
  responses?: Maybe<ResponseConnection>;
  /** Result sharing configuration for survey */
  shareResultsSettings?: Maybe<ShareResultSettings>;
  /** Returns current prices for a new price list (Stripe based) */
  stripePriceList?: Maybe<StripePricing>;
  /** @deprecated moved to user.submittedSurveysThisMonth */
  submittedSurveysThisMonth: Scalars['Int']['output'];
  supportedLanguageCodes: Array<Scalars['LanguageCode']['output']>;
  supportedLocaleCodes: Array<Scalars['LocaleCode']['output']>;
  /**
   * Survey data and settings
   * @deprecated use surveyNew instead (will be moved back with id argument in the future - survey(id))
   */
  survey?: Maybe<Survey>;
  /** @deprecated use surveyConnection instead */
  surveyCards?: Maybe<CardConnection>;
  /** @deprecated use surveyOffsetConnection instead */
  surveyCardsPage?: Maybe<CardConnection>;
  /** List of surveys */
  surveyConnection?: Maybe<SurveyConnection>;
  surveyMotives: Array<SurveyMotiveType>;
  /** Survey data and settings */
  surveyNew?: Maybe<Survey>;
  /** List of surveys */
  surveyOffsetConnection?: Maybe<SurveyConnection>;
  surveyPublic?: Maybe<Survey>;
  surveyPublicIdentification?: Maybe<SurveyIdentification>;
  surveyTemplates?: Maybe<SurveyTemplatesRoot>;
  templates?: Maybe<Array<SurveyTemplateCategory>>;
  /**
   * List of days with counts of traffic events (visits, submits)
   * @deprecated moved to survey.trafficHistory
   */
  trafficHistory: Array<TrafficHistoryItem>;
  /** Fetch application strings with its translations */
  translationStrings?: Maybe<Scalars['Json']['output']>;
  unlockedService?: Maybe<UnlockedServiceType>;
  /** Information about current authenticated user */
  user?: Maybe<User>;
  /** Validate password before any change */
  validatePassword: Scalars['Boolean']['output'];
  validateVAT: Scalars['Boolean']['output'];
};


/** Response filter related queries */
export type QueryAppStringsArgs = {
  input: TranslationQueryInput;
};


/** Response filter related queries */
export type QueryChoicedPivotDataArgs = {
  primaryQuestion: Scalars['Int']['input'];
  secondaryQuestion: Scalars['Int']['input'];
  survey: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryExportDoneArgs = {
  token: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryFilloutDurationStatsArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryFilloutStatusStatsArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryFiltersArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryInvitationListPageArgs = {
  page?: InputMaybe<Scalars['PositiveNumber']['input']>;
  perPage: Scalars['PositiveNumber']['input'];
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryInvitationRecipientListPageArgs = {
  invitation: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['PositiveNumber']['input']>;
  perPage: Scalars['PositiveNumber']['input'];
  withStatusEither?: InputMaybe<Array<DeliveryStatus>>;
};


/** Response filter related queries */
export type QueryInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryLastInvitationMessageArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryPriceListArgs = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
};


/** Response filter related queries */
export type QueryQuestionArgs = {
  id: Scalars['ID']['input'];
  surveyUID: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryQuestionAggregateArgs = {
  question: Scalars['Int']['input'];
  survey: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryQuestionListArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryReportDoneArgs = {
  token: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryRespondentSourcesArgs = {
  survey: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryResponseArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  neighbor?: InputMaybe<NeighborEnum>;
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryResponsePageArgs = {
  onlyUntilId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage: Scalars['Int']['input'];
  sorting?: InputMaybe<ResponseSortingInput>;
  totalCountFilters?: InputMaybe<CountFilters>;
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryResponsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryShareResultsSettingsArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryStripePriceListArgs = {
  currencyCode?: InputMaybe<Scalars['String']['input']>;
};


/** Response filter related queries */
export type QuerySurveyArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QuerySurveyCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Response filter related queries */
export type QuerySurveyCardsPageArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


/** Response filter related queries */
export type QuerySurveyConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Response filter related queries */
export type QuerySurveyNewArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QuerySurveyOffsetConnectionArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};


/** Response filter related queries */
export type QuerySurveyPublicArgs = {
  publicIdentifier: Scalars['ID']['input'];
};


/** Response filter related queries */
export type QuerySurveyPublicIdentificationArgs = {
  discriminator?: InputMaybe<Scalars['String']['input']>;
  identification: Scalars['String']['input'];
  type?: InputMaybe<SurveyIdentificationType>;
};


/** Response filter related queries */
export type QueryTemplatesArgs = {
  language?: InputMaybe<Scalars['String']['input']>;
};


/** Response filter related queries */
export type QueryTrafficHistoryArgs = {
  uid: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryTranslationStringsArgs = {
  input: TranslationStringsInput;
};


/** Response filter related queries */
export type QueryUserArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Response filter related queries */
export type QueryValidatePasswordArgs = {
  password: Scalars['String']['input'];
};


/** Response filter related queries */
export type QueryValidateVatArgs = {
  vat: Scalars['String']['input'];
};

/** All possible question result types */
export type QuestionAggregateInterface = {
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
};

/** Common question settings */
export type QuestionInterface = {
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  text: Scalars['String']['output'];
  video?: Maybe<Video>;
};

export type QuestionPreferencesInput = {
  question: Scalars['Int']['input'];
  uid: Scalars['String']['input'];
  value: Scalars['Json']['input'];
};

export type QuestionSubitem = {
  __typename?: 'QuestionSubitem';
  id: Scalars['Int']['output'];
  ordinal: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

/** Interface for SurveyDashboard tile */
export type QuestionTile = SurveyDashboardTileInterface & {
  __typename?: 'QuestionTile';
  id: Scalars['ID']['output'];
  question: QuestionInterface;
  result?: Maybe<QuestionAggregateInterface>;
};

/** Question with number of stars to e.g. evaluate quality of something */
export type RatingQuestion = ContentItem & QuestionInterface & {
  __typename?: 'RatingQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  pointCount: Scalars['Int']['output'];
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  rowCollectionId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  video?: Maybe<Video>;
};

/** Base options common for all question mutations */
export type RatingQuestionInput = {
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  pointCount: Scalars['Int']['input'];
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** Contact details of one person invited to the survey */
export type RecipientInput = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum RecipientSource {
  /** Original invitation */
  Invitation = 'INVITATION',
  /** Following reminder after not answering */
  Reminder = 'REMINDER'
}

/** Staus of a single invitation recipient */
export type RecipientStatus = {
  __typename?: 'RecipientStatus';
  /** Detailed status of the invitation delivery or engagement */
  deliveryStatus: DeliveryStatus;
  email: Scalars['String']['output'];
  /** Recipient has filled out and submittesst the survey for the invitation */
  hasSubmitted: Scalars['Boolean']['output'];
  /** Invitation was not delivered (or rejected) yet */
  isPending: Scalars['Boolean']['output'];
  source: RecipientSource;
  /** Date when the invitation (or reminder) was scheduled */
  sourceDate: Scalars['String']['output'];
  /** Point in time when the invitation (or reminder) was scheduled */
  sourceDateTime: Scalars['String']['output'];
  /** Time when the invitation (or reminder) was scheduled */
  sourceTime: Scalars['String']['output'];
  /** Date and time of the survey sumbission */
  submitDateTime?: Maybe<Scalars['String']['output']>;
  tag: Scalars['ID']['output'];
};

/** A connection to a list of items. */
export type RecipientStatusConnection = {
  __typename?: 'RecipientStatusConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<RecipientStatusEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RecipientStatusEdge = {
  __typename?: 'RecipientStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RecipientStatus>;
};

/** new setting for the submit redirect */
export type RedirectSettingsInput = {
  active: Scalars['Boolean']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Specification of a invitation remider message */
export type ReminderMessageInput = {
  message?: InputMaybe<SurveyInvitationMessageInput>;
  sendAfterDays: Scalars['Int']['input'];
};

export type RenewalDiscount = {
  __typename?: 'RenewalDiscount';
  /** Discount percentage */
  discount?: Maybe<Scalars['Float']['output']>;
  eligible: Scalars['Boolean']['output'];
};

/** Activity event captured on report download */
export type ReportDownload = ActivityInterface & {
  __typename?: 'ReportDownload';
  data?: Maybe<ReportDownloadData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type ReportDownloadData = {
  __typename?: 'ReportDownloadData';
  reportType?: Maybe<ReportExportType>;
  /** Name of a survey */
  surveyName: Scalars['String']['output'];
};

export enum ReportExportType {
  Csv = 'CSV',
  /** Word 2013+ document (OpenXML format) */
  Docx = 'DOCX',
  Excel = 'EXCEL',
  /** PDF */
  Pdf = 'PDF',
  /** Powerpoint 2013+ presenation (OpenXML format) */
  Pptx = 'PPTX',
  Sheets = 'SHEETS'
}

export type ReportInput = {
  /** Whether to include all data in the report (only admin can use this input) */
  fullReport?: InputMaybe<Scalars['Boolean']['input']>;
  parts?: InputMaybe<ReportPartsInput>;
  type: ReportType;
  uid: Scalars['String']['input'];
};

export type ReportPartsInput = {
  resultCharts: Scalars['Boolean']['input'];
  resultTables: Scalars['Boolean']['input'];
  results: Scalars['Boolean']['input'];
  stats: Scalars['Boolean']['input'];
  survey: Scalars['Boolean']['input'];
};

export type ReportProgress = {
  __typename?: 'ReportProgress';
  /** Export is finished */
  done: Scalars['Boolean']['output'];
  /** Number of report parts including question results that were processed so far */
  parts: Scalars['Int']['output'];
  /** Progress in percent */
  percent: Scalars['Float']['output'];
};

export type ReportStartResponse = {
  __typename?: 'ReportStartResponse';
  exportToken: Scalars['String']['output'];
};

export enum ReportType {
  /** Word 2013+ document (OpenXML format) */
  Docx = 'DOCX',
  /** PDF */
  Pdf = 'PDF',
  /** Powerpoint 2013+ presenation (OpenXML format) */
  Pptx = 'PPTX'
}

export type RequestCustomPlanInput = {
  email: Scalars['String']['input'];
};

export type RequestTeamSeatsInput = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  seatsCount?: InputMaybe<Scalars['PositiveNumber']['input']>;
};

/** Statistic of how many respondents came from a specific source (link on a website,social networks etc.) */
export type RespondentSourcesAggregate = {
  __typename?: 'RespondentSourcesAggregate';
  breakdown: Array<SourceStatsItem>;
  respondentsTotal: Scalars['Int']['output'];
};

/** One complete submitted response to a survey */
export type Response = {
  __typename?: 'Response';
  /** Answer data */
  answers: Array<IndividualQuestionAnswer>;
  /** Response data were erased by the user */
  deleted: Scalars['Boolean']['output'];
  /** Response was discarded as invalid/unusable by the user */
  discarded: Scalars['Boolean']['output'];
  /** E-mail address of a respondent invited to the survey using e-mail invitations */
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Response data is invalid */
  invalid: Scalars['Boolean']['output'];
  /** Ordinal number of the response chronologically since start (ie. 5 means 5th submitted) */
  number: Scalars['Int']['output'];
  parameterValues?: Maybe<Scalars['Json']['output']>;
  /** How/where the respondent found the survey link */
  source?: Maybe<VisitSource>;
  /** Date and time when the response was submitted */
  submitted: Scalars['DateTime']['output'];
  /** Time spent filling out the response */
  timeSpent: Duration;
};

export type ResponseBrowsingInfo = {
  __typename?: 'ResponseBrowsingInfo';
  cursor?: Maybe<Scalars['String']['output']>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  response?: Maybe<Response>;
};

export type ResponseCollectionPackage = {
  __typename?: 'ResponseCollectionPackage';
  monthlyAmount?: Maybe<Scalars['Float']['output']>;
  quantity: Scalars['Int']['output'];
  yearlyAmount?: Maybe<Scalars['Float']['output']>;
};

/** A connection to a list of items. */
export type ResponseConnection = {
  __typename?: 'ResponseConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ResponseEdge>>>;
  overLimitCount: Scalars['Int']['output'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  toBeRemovedCount: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ResponseDurationFilter = {
  __typename?: 'ResponseDurationFilter';
  id: Scalars['ID']['output'];
  maximum?: Maybe<Scalars['Int']['output']>;
  minimum: Scalars['Int']['output'];
  type: DurationEnum;
};

/** An edge in a connection. */
export type ResponseEdge = {
  __typename?: 'ResponseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Response>;
};

export enum ResponseLimitStrategyEnum {
  Monthly = 'MONTHLY',
  Total = 'TOTAL'
}

export type ResponseSortingInput = {
  submitted?: InputMaybe<SortDirection>;
};

export type ResponseStorage = {
  __typename?: 'ResponseStorage';
  region: StorageRegion;
};

/** Checkout/Upgrade source tracking element group, such as pricing, dialog, mail, etc. */
export type SaleSourceInput = {
  element: Scalars['String']['input'];
  preCheckoutVersion?: InputMaybe<Scalars['String']['input']>;
  section: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Specification of a scheduled invitation message */
export type ScheduledMessageInput = {
  message?: InputMaybe<SurveyInvitationMessageInput>;
  schedule?: InputMaybe<InvitationScheduleInput>;
};

/** request to delete all data of the response (metadata and responses) */
export type ScrubResponsesInput = {
  responseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  uid: Scalars['String']['input'];
};

export enum SegmentationSurveyStatusType {
  Finished = 'FINISHED',
  Skipped = 'SKIPPED'
}

/** Semantic differential row labels */
export type SemanticDiffLabels = {
  __typename?: 'SemanticDiffLabels';
  leftLabel: Scalars['String']['output'];
  rightLabel: Scalars['String']['output'];
};

/** Base options common for all question mutations */
export type SemanticDiffQuestionInput = {
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  pointCount: Scalars['Int']['input'];
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  rows: Array<InputMaybe<SemanticInput>>;
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** Count of respondents for a specific choice from the differential scale */
export type SemanticDifferentialBreakdownItem = {
  __typename?: 'SemanticDifferentialBreakdownItem';
  count: Scalars['Int']['output'];
  leftLabel: Scalars['String']['output'];
  rightLabel: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

/** Semantic differential, answered by choosing one of possible choices between two extremes */
export type SemanticDifferentialQuestion = ContentItem & QuestionInterface & {
  __typename?: 'SemanticDifferentialQuestion';
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  pointCount: Scalars['Int']['output'];
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  rows: Array<SemanticDifferentialRow>;
  text: Scalars['String']['output'];
  video?: Maybe<Video>;
};

/** Summary result of semantic differential question answers */
export type SemanticDifferentialQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'SemanticDifferentialQuestionAggregate';
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
  rowResults: Array<SemanticDifferentialRowResult>;
};

/** One row of semantic differential */
export type SemanticDifferentialRow = {
  __typename?: 'SemanticDifferentialRow';
  id: Scalars['Int']['output'];
  leftLabel: Scalars['String']['output'];
  ordinal: Scalars['Int']['output'];
  rightLabel: Scalars['String']['output'];
  semanticRowCollectionId: Scalars['String']['output'];
};

/** Result of one semantic differential row */
export type SemanticDifferentialRowResult = {
  __typename?: 'SemanticDifferentialRowResult';
  average: Scalars['Float']['output'];
  breakdown: Array<SemanticDifferentialBreakdownItem>;
  labels: SemanticDiffLabels;
  rowId: Scalars['Int']['output'];
};


/** Result of one semantic differential row */
export type SemanticDifferentialRowResultAverageArgs = {
  decimals?: InputMaybe<Scalars['Int']['input']>;
};

export type SemanticInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  leftLabel: Scalars['String']['input'];
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  rightLabel: Scalars['String']['input'];
};

export type SendDashboardSharingUrlInput = {
  dashboardId: Scalars['ID']['input'];
  emails: Array<Scalars['String']['input']>;
};

export type SendPreviewDashboardReportInput = {
  dashboardId: Scalars['ID']['input'];
};

/** Input to set member(s) invitation data */
export type SendTeamInvitationInput = {
  emails: Array<Scalars['String']['input']>;
  role: TeamMemberRole;
};

export type SendTeamInvitationResult = {
  __typename?: 'SendTeamInvitationResult';
  addedUsers: Scalars['Int']['output'];
};

/** Activity event captured when sending email invitations */
export type SendingOutInvitations = ActivityInterface & {
  __typename?: 'SendingOutInvitations';
  data?: Maybe<SendingOutInvitationsData>;
  email: Scalars['String']['output'];
  memberId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  userName: Scalars['String']['output'];
};

export type SendingOutInvitationsData = {
  __typename?: 'SendingOutInvitationsData';
  /** List of emails where the member send the invitation */
  emails?: Maybe<Array<Scalars['String']['output']>>;
};

export type Service = {
  __typename?: 'Service';
  activeSince?: Maybe<Scalars['DateTime']['output']>;
  activeTill?: Maybe<Scalars['DateTime']['output']>;
  autorenewal: Scalars['Boolean']['output'];
  changeCosts?: Maybe<UpgradeOptions>;
  /**
   * Indicates if user is eligible for renewal discount
   * @deprecated Use renewalDiscount.eligible
   */
  eligibleForRenewalDiscount: Scalars['Boolean']['output'];
  featureList: Array<Scalars['String']['output']>;
  getChangePrice?: Maybe<UpdateResult>;
  getIncreaseSeatsPrice?: Maybe<IncreaseSeatsResult>;
  getStripeUpgradePrice: StripeUpgradePreviewResult;
  identifier?: Maybe<Scalars['String']['output']>;
  invitationRecipientLimit: Scalars['Int']['output'];
  /** @deprecated demo is being replaced by trial. Use user.activeService.isTrial instead */
  isDemo: Scalars['Boolean']['output'];
  isSubscription: Scalars['Boolean']['output'];
  isTrial: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nextBilling?: Maybe<BillingDate>;
  nextBillingSeatsCount?: Maybe<Scalars['Int']['output']>;
  /** Indicates if user has paid proforma for the next period */
  paidNextPeriod: Scalars['Boolean']['output'];
  parameters?: Maybe<Scalars['Json']['output']>;
  period?: Maybe<Scalars['ServicePeriod']['output']>;
  /** User pricing migration information */
  pricingChange?: Maybe<PricingChange>;
  provider: Scalars['String']['output'];
  renewalDiscount: RenewalDiscount;
  responseCollectionLimit?: Maybe<Scalars['Int']['output']>;
  responseLimit?: Maybe<Scalars['Int']['output']>;
  responseLimitStrategy: ResponseLimitStrategyEnum;
  responseRemovalDate?: Maybe<Scalars['DateTime']['output']>;
  responsesRemovalCount?: Maybe<Scalars['Int']['output']>;
  subscriptionReference?: Maybe<Scalars['String']['output']>;
  trialSource?: Maybe<Scalars['String']['output']>;
  twoYearSeatPrice?: Maybe<Scalars['Float']['output']>;
  yearSeatPrice?: Maybe<Scalars['Float']['output']>;
};


export type ServiceChangeCostsArgs = {
  type: PremiumServiceType;
};


export type ServiceGetChangePriceArgs = {
  period: PremiumServicePeriod;
  type: PremiumServiceType;
};


export type ServiceGetIncreaseSeatsPriceArgs = {
  quantity: Scalars['Int']['input'];
};


export type ServiceGetStripeUpgradePriceArgs = {
  input: StripeUpgradeInput;
};

export type SetDashboardReportInput = {
  dashboardId: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  repeat?: InputMaybe<AutomatedReportsRepeat>;
  to?: InputMaybe<Array<Scalars['String']['input']>>;
  when?: InputMaybe<Scalars['DateTimeImmutable']['input']>;
};

export type SetDefaultResponseStorageInput = {
  region?: InputMaybe<StorageRegion>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetSurveyPublicDashboardInput = {
  dashboardId?: InputMaybe<Scalars['ID']['input']>;
  surveyId: Scalars['ID']['input'];
};

export type SetSurveyResponseStorageInput = {
  region?: InputMaybe<StorageRegion>;
  surveyId: Scalars['ID']['input'];
};

/** Share results settings */
export type ShareResultSettings = {
  __typename?: 'ShareResultSettings';
  enableResultsAndResponsesExport: Scalars['Boolean']['output'];
  introductoryText?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  passwordActive: Scalars['Boolean']['output'];
  published: Scalars['Boolean']['output'];
  showIndividualResponses: Scalars['Boolean']['output'];
  showSharing: Scalars['Boolean']['output'];
  showSurveyStatistics: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

/** Share results settings input */
export type ShareResultSettingsInput = {
  enableResultsAndResponsesExport?: InputMaybe<Scalars['Boolean']['input']>;
  introductoryText?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordActive?: InputMaybe<Scalars['Boolean']['input']>;
  published: Scalars['Boolean']['input'];
  showIndividualResponses?: InputMaybe<Scalars['Boolean']['input']>;
  showSharing?: InputMaybe<Scalars['Boolean']['input']>;
  showSurveyStatistics?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum SortDirection {
  /** Sort in ascending order, from smallest to biggest values */
  Asc = 'ASC',
  /** Sort in descending order, from biggest to smallest values */
  Desc = 'DESC'
}

export type SourceFilter = {
  __typename?: 'SourceFilter';
  id: Scalars['ID']['output'];
  type: VisitSource;
  value: Scalars['String']['output'];
};

export type SourceStatsItem = {
  __typename?: 'SourceStatsItem';
  count: Scalars['Int']['output'];
  value: VisitSource;
};

export type SplitPointsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

/** Base options common for all question mutations */
export type SplitPointsQuestionInput = {
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  pointCount: Scalars['Int']['input'];
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  rows: Array<InputMaybe<SplitPointsInput>>;
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  units: Scalars['String']['input'];
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** Summary of star rating question */
export type StarRatingQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'StarRatingQuestionAggregate';
  average: Scalars['Float']['output'];
  breakdown?: Maybe<Array<Maybe<BreakdownItem>>>;
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
};

export type StartSubscriptionInput = {
  currency: Scalars['String']['input'];
  period: PremiumServicePeriod;
  price: Scalars['Float']['input'];
  referenceId?: InputMaybe<Scalars['String']['input']>;
  seatCount?: InputMaybe<Scalars['Int']['input']>;
  seatPrice?: InputMaybe<Scalars['Float']['input']>;
  type: PremiumServiceType;
  userId: Scalars['ID']['input'];
};

export enum StorageRegion {
  Eu = 'EU',
  /** @deprecated This region is deprecated */
  Us = 'US'
}

export type StripeCheckoutLinkInput = {
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  period: StripePremiumServicePeriod;
  plan: Scalars['String']['input'];
  responseCollectionLimit?: InputMaybe<Scalars['Int']['input']>;
  saleSource?: InputMaybe<SaleSourceInput>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export enum StripePremiumServicePeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type StripePriceBasicPlan = StripePricePlan & {
  __typename?: 'StripePriceBasicPlan';
  features: Array<Scalars['String']['output']>;
  identifier: Scalars['String']['output'];
  invitationLimit?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Monthly price is already available for all packages (use StripePricePlan.monthlyAmount) */
  monthlyAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated use planName */
  name: Scalars['String']['output'];
  /** @deprecated use identifier */
  plan: Scalars['String']['output'];
  planName: Scalars['String']['output'];
  responseCollectionLimit?: Maybe<Scalars['Int']['output']>;
  responseCollectionPackages?: Maybe<Array<ResponseCollectionPackage>>;
  responseLimit?: Maybe<Scalars['Int']['output']>;
  yearlyAmount?: Maybe<Scalars['Float']['output']>;
};

export type StripePricePlan = {
  features: Array<Scalars['String']['output']>;
  identifier: Scalars['String']['output'];
  invitationLimit?: Maybe<Scalars['Int']['output']>;
  monthlyAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated use planName */
  name: Scalars['String']['output'];
  /** @deprecated use identifier */
  plan: Scalars['String']['output'];
  planName: Scalars['String']['output'];
  responseCollectionLimit?: Maybe<Scalars['Int']['output']>;
  responseCollectionPackages?: Maybe<Array<ResponseCollectionPackage>>;
  responseLimit?: Maybe<Scalars['Int']['output']>;
  yearlyAmount?: Maybe<Scalars['Float']['output']>;
};

export type StripePriceProPlan = StripePricePlan & {
  __typename?: 'StripePriceProPlan';
  features: Array<Scalars['String']['output']>;
  identifier: Scalars['String']['output'];
  invitationLimit?: Maybe<Scalars['Int']['output']>;
  monthlyAmount?: Maybe<Scalars['Float']['output']>;
  /** @deprecated use planName */
  name: Scalars['String']['output'];
  /** @deprecated use identifier */
  plan: Scalars['String']['output'];
  planName: Scalars['String']['output'];
  responseCollectionLimit?: Maybe<Scalars['Int']['output']>;
  responseCollectionPackages?: Maybe<Array<ResponseCollectionPackage>>;
  responseLimit?: Maybe<Scalars['Int']['output']>;
  seatsDefault: Scalars['Int']['output'];
  seatsMax?: Maybe<Scalars['Int']['output']>;
  yearlyAmount?: Maybe<Scalars['Float']['output']>;
  yearlySeatAmount?: Maybe<Scalars['Float']['output']>;
};

export type StripePricing = {
  __typename?: 'StripePricing';
  currencyCode: Scalars['String']['output'];
  experiment?: Maybe<Scalars['String']['output']>;
  plans?: Maybe<Array<StripePricePlan>>;
};

export type StripeUpgradeInput = {
  period?: InputMaybe<StripePremiumServicePeriod>;
  plan?: InputMaybe<Scalars['String']['input']>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  responseCollectionLimit?: InputMaybe<Scalars['Int']['input']>;
  saleSource?: InputMaybe<SaleSourceInput>;
  seats?: InputMaybe<Scalars['Int']['input']>;
};

export type StripeUpgradePreviewResult = {
  __typename?: 'StripeUpgradePreviewResult';
  actionRequiredSecret?: Maybe<Scalars['String']['output']>;
  /** Total amount to pay (incl. tax) */
  amount: Scalars['Float']['output'];
  couponAlreadyActive: Scalars['Boolean']['output'];
  couponDiscount?: Maybe<Scalars['Float']['output']>;
  currencyCode: Scalars['String']['output'];
  errors?: Maybe<Array<PricingClientErrorEnum>>;
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  nextBillingPrice?: Maybe<Scalars['Float']['output']>;
  /** Prorated discount for upgrade (exl. tax) */
  prorateDiscount?: Maybe<Scalars['Float']['output']>;
  /** Amount to pay (exl. tax) */
  subAmount?: Maybe<Scalars['Float']['output']>;
  taxRate?: Maybe<Scalars['Float']['output']>;
};

export type StripeUpgradeResult = {
  __typename?: 'StripeUpgradeResult';
  actionRequiredSecret?: Maybe<Scalars['String']['output']>;
  /** Total amount to pay (incl. tax) */
  amount: Scalars['Float']['output'];
  currencyCode: Scalars['String']['output'];
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
};

export type Subscriptions = {
  __typename?: 'Subscriptions';
  exportStatus?: Maybe<Progress>;
  reportStatus?: Maybe<ReportProgress>;
  subscriptionsStatusInfo: Scalars['String']['output'];
  surveyUpdate?: Maybe<Survey>;
};


export type SubscriptionsExportStatusArgs = {
  token: Scalars['String']['input'];
};


export type SubscriptionsReportStatusArgs = {
  token: Scalars['String']['input'];
};

export type Survey = {
  __typename?: 'Survey';
  active: Scalars['Boolean']['output'];
  /** Allow respondents to answer the survey more than one time */
  allowRepeat?: Maybe<Scalars['Boolean']['output']>;
  /** Allow the respondents to backtrack in the survey and view or change questions they already answered */
  allowReturn: Scalars['Boolean']['output'];
  availability?: Maybe<Availability>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  /**
   * Calculates pivot table data for 2 choiced questions, i.e. breakdowns of how many respondents chose a specific answers for both questions, for example how many chose option 1 for question 1 and option 2 from question 2 at the same time. The results are broken down according to primaryQuestion's answers first, and each primaryQuestion's answer option contains breakdown of secondaryQuestion's answers for respondents that chose given primaryQuestion's answer option.
   *
   */
  choicedPivotData?: Maybe<PivotData2D>;
  content?: Maybe<SurveyContent>;
  createdAt: Scalars['DateTime']['output'];
  customThemes: Array<Scalars['Json']['output']>;
  customUrl?: Maybe<CustomUrl>;
  dashboard?: Maybe<SurveyDashboard>;
  dashboards?: Maybe<Array<SurveyDashboard>>;
  dbId: Scalars['ID']['output'];
  /** Show a given webpage after respondent completes the survey (redirect to given URL) */
  exitUrl?: Maybe<SwitchableString>;
  /** Breakdown of how many vistors reached which stage - just arrived or quit patway, submitted, submitted but were discarded as unsuitable... */
  filloutStatusStats?: Maybe<FilloutStatusAggregate>;
  firstResponseAt?: Maybe<Scalars['DateTime']['output']>;
  footerText?: Maybe<Scalars['String']['output']>;
  hasCustomBackground: Scalars['Boolean']['output'];
  headerLogo?: Maybe<Scalars['String']['output']>;
  headerText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  integrations?: Maybe<SurveyIntegrations>;
  /** List of invitations for the survey */
  invitation?: Maybe<InvitationStatus>;
  /** List of invitations for the survey */
  invitationConnection?: Maybe<InvitationStatusConnection>;
  ipRestriction: IpRestriction;
  isAdEnabled: Scalars['Boolean']['output'];
  /** Automatically focus the next question when a question is answered */
  isAutomaticNextQuestionActive: Scalars['Boolean']['output'];
  isDemo?: Maybe<Scalars['Boolean']['output']>;
  isFooterShown: Scalars['Boolean']['output'];
  isIndexingAllowed: Scalars['Boolean']['output'];
  /** @deprecated moved to poweredBySurvioButton */
  isPoweredBySurvioShown: Scalars['Boolean']['output'];
  /** Show a widget that allows respondents to easily share the survey with others on social networks etc. after they complete the survey */
  isSharingEnabled: Scalars['Boolean']['output'];
  language: SurveyLanguageUnion;
  lastChangedAt: Scalars['DateTime']['output'];
  lastResponseAt?: Maybe<Scalars['DateTime']['output']>;
  logoExtension?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  /** Show ordinal numbers next to the questions in the survey */
  numberQuestions: Scalars['Boolean']['output'];
  overlay?: Maybe<Scalars['String']['output']>;
  owner: User;
  passwordProtection: PasswordProtection;
  poweredBySurvioButton: PoweredBySurvioButton;
  previewImage?: Maybe<Scalars['String']['output']>;
  privateIdentifier: Scalars['String']['output'];
  /** Thank you page shared dashboard */
  publicDashboard?: Maybe<SurveyDashboard>;
  /** Thank you page shared dashboard URL */
  publicDashboardURL?: Maybe<Scalars['String']['output']>;
  publicIdentifier: Scalars['String']['output'];
  publicUrl: Scalars['String']['output'];
  question?: Maybe<QuestionInterface>;
  /** List of questions in a survey */
  questions?: Maybe<Array<QuestionInterface>>;
  /** Respondent source statistics */
  respondentSources?: Maybe<RespondentSourcesAggregate>;
  response?: Maybe<ResponseBrowsingInfo>;
  /** list of individual responses for the survey */
  responseConnection?: Maybe<ResponseConnection>;
  responseCount: Scalars['Int']['output'];
  /** When survey is submitted, send a notification to user's email */
  responseNotifications: Scalars['Boolean']['output'];
  /** Include answers in the notification email */
  responseNotificationsIncludeAnswers: Scalars['Boolean']['output'];
  /** When survey is submitted, send a notification to a configured Slack channel */
  responseNotificationsSlack: Scalars['Boolean']['output'];
  /** @deprecated moved to survey (here) */
  settings?: Maybe<SurveySettings>;
  sharingMetadata: SurveySharingMetadata;
  /** Storage for responses */
  storage?: Maybe<ResponseStorage>;
  theme: Scalars['Json']['output'];
  title: Scalars['String']['output'];
  totalRespondents: Scalars['Int']['output'];
  /** Google Analytics tracking settings */
  tracking: Tracking;
  /** List of days with counts of traffic events (visits, submits) */
  trafficHistory: Array<TrafficHistoryItem>;
  /** @deprecated moved to id */
  uid: Scalars['String']['output'];
  urlParameters: UrlParameters;
  workspace?: Maybe<Workspace>;
};


export type SurveyChoicedPivotDataArgs = {
  questionId_1: Scalars['ID']['input'];
  questionId_2: Scalars['ID']['input'];
};


export type SurveyDashboardArgs = {
  dashboardId: Scalars['ID']['input'];
};


export type SurveyInvitationArgs = {
  id: Scalars['ID']['input'];
};


export type SurveyInvitationConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SurveyQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type SurveyResponseArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  neighbor?: InputMaybe<NeighborEnum>;
};


export type SurveyResponseConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Custom background for a survey */
export type SurveyBackground = {
  __typename?: 'SurveyBackground';
  custom: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  thumb: Scalars['LocalUrl']['output'];
  url: BackgroundVersions;
};

/** Summary item for user's dashboard for example */
export type SurveyCard = {
  __typename?: 'SurveyCard';
  /** @deprecated moved to survey.active */
  active: Scalars['Boolean']['output'];
  /** @deprecated moved to survey.backgroundColor */
  backgroundColor?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to survey.previewImage */
  backgroundImage?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to survey.hasCustomBackground */
  hasCustomBackground: Scalars['Boolean']['output'];
  /** @deprecated moved to survey.logoUrl */
  logo?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to survey.note */
  note?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to survey.overlay */
  overlay?: Maybe<Scalars['String']['output']>;
  /** @deprecated moved to survey.responseCount */
  responses: Scalars['Int']['output'];
  /** @deprecated moved to survey.title */
  title: Scalars['String']['output'];
  /** @deprecated moved to survey.id */
  uid: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type SurveyConnection = {
  __typename?: 'SurveyConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<SurveyEdge>>>;
  orderBy?: Maybe<MySurveysOrderBy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
  viewStyle?: Maybe<ViewStyle>;
};

/** Content of the survey */
export type SurveyContent = {
  __typename?: 'SurveyContent';
  introPage: IntroPage;
  mainPages: Array<SurveyPage>;
  repeatButtonText: Scalars['String']['output'];
  submitButtonText: Scalars['String']['output'];
  thanksPage?: Maybe<ThanksPage>;
};

export type SurveyDashboard = {
  __typename?: 'SurveyDashboard';
  automatedReports?: Maybe<AutomatedReports>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultTheme?: Maybe<ChartTheme>;
  /** Currently defined filters for the dashboard results */
  filters: DashboardFilters;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  /** Sharing configuration */
  sharing?: Maybe<DashboardSharing>;
  themes?: Maybe<Array<Maybe<ChartTheme>>>;
  tiles?: Maybe<Array<DashboardTileTypes>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SurveyDashboardAnswerFilterInput = {
  answers: Array<AnswerFilterInput>;
  question: Scalars['ID']['input'];
  surveyDashboardId: Scalars['ID']['input'];
};

export type SurveyDashboardDatePeriodFilter = {
  __typename?: 'SurveyDashboardDatePeriodFilter';
  from?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  periodType: DatePeriodType;
  till?: Maybe<Scalars['DateTime']['output']>;
};

export type SurveyDashboardDatePeriodFilterInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  periodType: DatePeriodType;
  surveyDashboardId: Scalars['ID']['input'];
  till?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SurveyDashboardQuestionAnalyticsPreferencesInput = {
  tileId: Scalars['ID']['input'];
  value: Scalars['Json']['input'];
};

export type SurveyDashboardResponseDurationFilterInput = {
  surveyDashboardId: Scalars['ID']['input'];
  sysidList?: InputMaybe<Array<DurationEnum>>;
};

export type SurveyDashboardSourceFilterInput = {
  sourceList?: InputMaybe<Array<VisitSource>>;
  surveyDashboardId: Scalars['ID']['input'];
};

/** Interface for SurveyDashboard tile */
export type SurveyDashboardTileInterface = {
  id: Scalars['ID']['output'];
};

/** An edge in a connection. */
export type SurveyEdge = {
  __typename?: 'SurveyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Survey>;
};

export type SurveyIdentification = {
  __typename?: 'SurveyIdentification';
  availability: Availability;
  deleted: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invitationAnswered?: Maybe<Scalars['Boolean']['output']>;
  publicId: Scalars['ID']['output'];
  v2: Scalars['Boolean']['output'];
};

/** Identification of a survey in the system */
export type SurveyIdentificationInput = {
  uid: Scalars['String']['input'];
};

export enum SurveyIdentificationType {
  /** custom URL */
  Custom = 'CUSTOM',
  /** invitation recipient tag */
  Invitation = 'INVITATION',
  /** Regular alphanumeric identifier of survey that does not have custom URL */
  PublicId = 'PUBLIC_ID',
  /** Individual invitation tag (using fillout-request API endpoint) */
  Request = 'REQUEST'
}

export type SurveyIntegrations = {
  __typename?: 'SurveyIntegrations';
  dropBox?: Maybe<IntegrationSettings>;
  googleAnalytics?: Maybe<Tracking>;
  googleDrive?: Maybe<IntegrationSettings>;
  googleSheets?: Maybe<IntegrationSettingsSheets>;
  oneDrive?: Maybe<IntegrationSettings>;
};

/** Specification of a invitation to delete */
export type SurveyInvitationDeleteRequest = {
  /** ID of the invitation to delete */
  id: Scalars['Int']['input'];
  /** ID of survey */
  uid: Scalars['String']['input'];
};

export type SurveyInvitationMessage = {
  __typename?: 'SurveyInvitationMessage';
  buttonColor: Scalars['HexRgbColor']['output'];
  buttonText: Scalars['String']['output'];
  closingText: Scalars['String']['output'];
  embeddedQuestion?: Maybe<Scalars['Boolean']['output']>;
  fromName: Scalars['String']['output'];
  introText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Invitation message settings */
export type SurveyInvitationMessageInput = {
  align?: InputMaybe<HorizontalAlign>;
  buttonColor: Scalars['HexRgbColor']['input'];
  buttonText: Scalars['String']['input'];
  closingText: Scalars['String']['input'];
  embeddedQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  fromName: Scalars['String']['input'];
  introText: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Specification of a new invitation */
export type SurveyInvitationRequest = {
  embeddedQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  initialInvitation: ScheduledMessageInput;
  recipients: Array<RecipientInput>;
  reminder?: InputMaybe<ReminderMessageInput>;
  /** ID of survey to invite to */
  uid: Scalars['String']['input'];
};

/** Allows different language string sources to be specified */
export type SurveyLanguageUnion = CustomSurveyLanguage | SystemSurveyLanguage;

export type SurveyMotiveType = {
  __typename?: 'SurveyMotiveType';
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  isNameTranslated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orderPriority: Scalars['Int']['output'];
  theme?: Maybe<Scalars['Json']['output']>;
};

/** Regular survey page with questions and freeform text blocks */
export type SurveyPage = {
  __typename?: 'SurveyPage';
  hash: Scalars['ID']['output'];
  id: Scalars['Int']['output'];
  /** content of the page (text blocks and questions) */
  items: Array<AllContentItems>;
  /** ordinal number of the page */
  position: Scalars['Int']['output'];
};

/** Various behavior settings */
export type SurveySettings = {
  __typename?: 'SurveySettings';
  /** Allow respondents to answer the survey more than one time */
  allowRepeat?: Maybe<Scalars['Boolean']['output']>;
  /** Allow the respondents to backtrack in the survey and view or change questions they already answered */
  allowReturn: Scalars['Boolean']['output'];
  availability?: Maybe<Availability>;
  /** Show a given webpage after respondent completes the survey (redirect to given URL) */
  exitUrl?: Maybe<SwitchableString>;
  footerText?: Maybe<Scalars['String']['output']>;
  headerLogo?: Maybe<Scalars['String']['output']>;
  headerText?: Maybe<Scalars['String']['output']>;
  ipRestriction: IpRestriction;
  isAdEnabled: Scalars['Boolean']['output'];
  /** Automatically focus the next question when a question is answered */
  isAutomaticNextQuestionActive: Scalars['Boolean']['output'];
  isFooterShown: Scalars['Boolean']['output'];
  isPoweredBySurvioShown: Scalars['Boolean']['output'];
  /** Show a widget that allows respondents to easily share the survey with others on social networks etc. after they complete the survey */
  isSharingEnabled: Scalars['Boolean']['output'];
  language: SurveyLanguageUnion;
  /** @deprecated moved to language.code (for SurveySystemLanguage) */
  languageCode: Scalars['String']['output'];
  /** @deprecated moved to language.localeCode (for SurveySystemLanguage) */
  localeCode: Scalars['String']['output'];
  /** Show ordinal numbers next to the questions in the survey */
  numberQuestions: Scalars['Boolean']['output'];
  passwordProtection: PasswordProtection;
  /** When survey is submitted, send a notification to user's email */
  responseNotifications: Scalars['Boolean']['output'];
  /** Include answers in the notification email */
  responseNotificationsIncludeAnswers: Scalars['Boolean']['output'];
  /** When survey is submitted, send a notification to a configured Slack channel */
  responseNotificationsSlack: Scalars['Boolean']['output'];
  /** Graphical theme - colors of buttons and other elements, background settings etc. */
  theme?: Maybe<Scalars['Json']['output']>;
  /** Google Analytics tracking settings */
  tracking: Tracking;
  urlParameters: UrlParameters;
};

/** settings update request data */
export type SurveySettingsUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowRepeat?: InputMaybe<Scalars['Boolean']['input']>;
  allowReturn?: InputMaybe<Scalars['Boolean']['input']>;
  clearFrom?: InputMaybe<Scalars['Boolean']['input']>;
  clearTill?: InputMaybe<Scalars['Boolean']['input']>;
  /** use specified user defined survey language (overrides languageCode) */
  customLanguage?: InputMaybe<Scalars['Int']['input']>;
  deadlinesActive?: InputMaybe<Scalars['Boolean']['input']>;
  exitUrl?: InputMaybe<RedirectSettingsInput>;
  footerText?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  headerLogo?: InputMaybe<Scalars['LogoUpload']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  ipRestriction?: InputMaybe<IpProtectionSettingsInput>;
  isAdEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isAutomaticNextQuestionActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFooterShown?: InputMaybe<Scalars['Boolean']['input']>;
  isPoweredBySurvioShown?: InputMaybe<Scalars['Boolean']['input']>;
  isSharingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  numberQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  passwordProtection?: InputMaybe<PasswordSettingsInput>;
  removeHeaderLogo?: InputMaybe<Scalars['Boolean']['input']>;
  responseNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  responseNotificationsIncludeAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  responseNotificationsSlack?: InputMaybe<Scalars['Boolean']['input']>;
  theme?: InputMaybe<Scalars['Json']['input']>;
  till?: InputMaybe<Scalars['String']['input']>;
  tracking?: InputMaybe<TrackingSettingsInput>;
  /** string identifier used in the app */
  uid: Scalars['String']['input'];
  urlParameters?: InputMaybe<ParameterSettingsInput>;
};

/** Status of the mutation request */
export type SurveySettingsUpdateResult = {
  __typename?: 'SurveySettingsUpdateResult';
  current?: Maybe<SurveySettings>;
  message?: Maybe<Scalars['String']['output']>;
  newIp?: Maybe<Scalars['Int']['output']>;
  newParameter?: Maybe<Scalars['Int']['output']>;
  success: Scalars['Boolean']['output'];
  textUpdates?: Maybe<Scalars['Json']['output']>;
};

/** Metadata for social network sharing */
export type SurveySharingMetadata = {
  __typename?: 'SurveySharingMetadata';
  description: Scalars['String']['output'];
  image?: Maybe<Scalars['LocalUrl']['output']>;
  title: Scalars['String']['output'];
};

export type SurveySharingMetadataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  /** set to true to remove current image */
  removeImage?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uid: Scalars['String']['input'];
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  designed: Scalars['Boolean']['output'];
  numberOfCopies: Scalars['Int']['output'];
  numberOfQuestions: Scalars['Int']['output'];
  publicId: Scalars['String']['output'];
  templateTypeId?: Maybe<Scalars['Int']['output']>;
  theme: TemplateTheme;
  title: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type SurveyTemplateCategory = {
  __typename?: 'SurveyTemplateCategory';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  sysid: Scalars['String']['output'];
  templates: Array<SurveyTemplate>;
  title: Scalars['String']['output'];
};

export type SurveyTemplatesRoot = {
  __typename?: 'SurveyTemplatesRoot';
  categories?: Maybe<Array<SurveyTemplateCategory>>;
  template?: Maybe<Survey>;
};


export type SurveyTemplatesRootTemplateArgs = {
  uid: Scalars['String']['input'];
};

export type SurveyTextImageInput = {
  image: Scalars['ImageUpload']['input'];
  uid: Scalars['String']['input'];
};

/** Status of the mutation request */
export type SurveyTextImageUploadResult = {
  __typename?: 'SurveyTextImageUploadResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['LocalUrl']['output']>;
};

/** Text setting input fo a specific survey */
export type SurveyTextInput = {
  /** New text value */
  text: Scalars['String']['input'];
  /** Survey ID */
  uid: Scalars['String']['input'];
};

/** A text value that's meant to ve regarded or disregarded by the system based on value of 'active' field */
export type SwitchableString = {
  __typename?: 'SwitchableString';
  active: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

/** Generally available survey language */
export type SystemSurveyLanguage = {
  __typename?: 'SystemSurveyLanguage';
  code: Scalars['LanguageCode']['output'];
  id: Scalars['Int']['output'];
  localeCode: Scalars['LocaleCode']['output'];
};

export type Team = {
  __typename?: 'Team';
  activitiesConnection?: Maybe<ActivitiesConnection>;
  freeSeatsCount?: Maybe<Scalars['PositiveNumber']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invitedBy?: Maybe<InvitedByMember>;
  language: Scalars['LanguageCode']['output'];
  loginPage: LoginPage;
  memberOffsetConnection?: Maybe<MemberConnection>;
  name?: Maybe<Scalars['String']['output']>;
  role: TeamMemberRole;
  totalSeatsCount?: Maybe<Scalars['PositiveNumber']['output']>;
};


export type TeamActivitiesConnectionArgs = {
  input?: InputMaybe<ActivityConnectionInput>;
};


export type TeamMemberOffsetConnectionArgs = {
  input?: InputMaybe<MemberOffsetConnectionInput>;
};

/** Possible team roles */
export enum TeamMemberRole {
  Admin = 'ADMIN',
  Editor = 'EDITOR',
  Owner = 'OWNER'
}

export type TemplateTheme = {
  __typename?: 'TemplateTheme';
  background?: Maybe<Scalars['String']['output']>;
  backgroundImage?: Maybe<Scalars['Url']['output']>;
  color?: Maybe<Scalars['String']['output']>;
};

/** Freeform answer given by the respondent */
export type TextAnswer = {
  __typename?: 'TextAnswer';
  id?: Maybe<Scalars['Int']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Block of text on a page */
export type TextBlock = ContentItem & {
  __typename?: 'TextBlock';
  id: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type TextBlockInput = {
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  page: Scalars['ID']['input'];
  /** Text inside of text block */
  text: Scalars['String']['input'];
};

/** Question answered by writing the answer in the text field */
export type TextQuestion = ContentItem & QuestionInterface & {
  __typename?: 'TextQuestion';
  answerLengthLimit: Scalars['Int']['output'];
  /** Question has answer data available */
  answered: Scalars['Boolean']['output'];
  edited: Scalars['Boolean']['output'];
  helpText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Question logic rules */
  logic?: Maybe<Scalars['Json']['output']>;
  number: Scalars['Int']['output'];
  /** Position on a page */
  ordinal: Scalars['Int']['output'];
  picture?: Maybe<Picture>;
  /**
   * Position on a page (same as ordinal)
   * @deprecated inconsistent name
   */
  position: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  /** Question's aggregate data, e.g. counts of how many respondents selected a specific answer option. Returned type depend on type of the question. */
  results?: Maybe<QuestionAggregateInterface>;
  /** @deprecated probably not used, will be removed */
  subtype: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textCollectionId: Scalars['String']['output'];
  type: TextQuestionEnum;
  video?: Maybe<Video>;
};

/** Text question aggregate result */
export type TextQuestionAggregate = QuestionAggregateInterface & {
  __typename?: 'TextQuestionAggregate';
  chartSettings?: Maybe<ChartSettings>;
  /** @deprecated use chartSettings instead */
  preferences?: Maybe<Scalars['Json']['output']>;
  respondentsTotal: Scalars['Int']['output'];
  results: Array<TextResult>;
};

/** Question types */
export enum TextQuestionEnum {
  /** Text question with field type - date */
  Date = 'DATE',
  /** Text question with field type - email */
  Email = 'EMAIL',
  /** Text question with field type - number */
  Number = 'NUMBER',
  /** Text question with field type - place */
  Place = 'PLACE',
  /**
   * Text question with field type - place
   * @deprecated typo in the value (lowercase L)
   */
  PlAce = 'PlACE',
  /** Text question */
  Text = 'TEXT'
}

/** Base options common for all question mutations */
export type TextQuestionInput = {
  answerLengthLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Remove any existing picture set for the question */
  deletePicture?: InputMaybe<Scalars['Boolean']['input']>;
  /** Marks item as edited or not. For edited = false, the question can be deleted without confirmation from the user. */
  edited?: InputMaybe<Scalars['Boolean']['input']>;
  helpText?: InputMaybe<Scalars['String']['input']>;
  /** ID of an edited item, pass null to create a new one */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Position within a page, first iten has ordinal 1, second 2 etc. Using an existing ordinal on a page inserts the item above item with the specified ordinal.
   *
   */
  ordinal?: InputMaybe<Scalars['Int']['input']>;
  /** ID of a survey page to which the item will be added */
  page: Scalars['ID']['input'];
  /** adds given image under the question text or replaces current one. No change if the field is left out or set as null. */
  picture?: InputMaybe<Scalars['ImageUpload']['input']>;
  /** The answer for the question will be required for any respondnets that encounter it */
  required: Scalars['Boolean']['input'];
  /**
   * New question logic rules - if not provided, current rules are preserved. To remove all current rules, pass an empty array - []. New questions do not have any rules set up by default
   *
   */
  rules?: InputMaybe<Array<InputMaybe<LogicRulesInput>>>;
  text: Scalars['String']['input'];
  /** type of Text Question */
  type: TextQuestionEnum;
  /** Youtube video to include under the question text. Replaces any existing media (picture or video) for the question. */
  video?: InputMaybe<YoutubeInput>;
};

/** Item of text question aggregate result */
export type TextResult = {
  __typename?: 'TextResult';
  count: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** Survey's exit page (shown after respondent submits the survey) */
export type ThanksPage = {
  __typename?: 'ThanksPage';
  /** Whether the user has thanks page feature (otherwise the page is branded) */
  available: Scalars['Boolean']['output'];
  /** Text of the page below the title (HTML formatted, containing only basic formatting - paragrapgs, alignment, bold, italic, links, pictures) */
  content?: Maybe<Scalars['String']['output']>;
  /** Whether the thanks page is enabled (thanks page was modified against the default one) */
  enabled: Scalars['Boolean']['output'];
  /** Logo on the page */
  logo?: Maybe<Logo>;
  /** Title of the page */
  title?: Maybe<Scalars['String']['output']>;
};

export type ThanksPageMutationInput = {
  /** content below the title (HTML basic formatting - paragraphs, alignment, bold, italic, links, pictures) */
  content?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['LogoUpload']['input']>;
  removeLogo?: InputMaybe<Scalars['Boolean']['input']>;
  /** title of a page, so far same as survey title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** string identifier used in the app */
  uid: Scalars['String']['input'];
};

export type ThemeInput = {
  /** full current theme object */
  theme: Scalars['Json']['input'];
  /** internal application survey ID */
  uid: Scalars['String']['input'];
};

export type ThemeMutations = {
  __typename?: 'ThemeMutations';
  /** sets one of the theme presets as the theme for the survey */
  choose: MutationResult;
  /** modifies given survey's theme preset given by its ID in theme.question.theme.id and sets the updated theme */
  customize: MutationResult;
  /** resets customized theme to defaults and removes it from customized theme storage */
  reset: MutationResult;
  /** sets a backgroud to current preset */
  setBackground: AddBackgroundResult;
};


export type ThemeMutationsChooseArgs = {
  input?: InputMaybe<ThemeInput>;
};


export type ThemeMutationsCustomizeArgs = {
  input?: InputMaybe<ThemeInput>;
};


export type ThemeMutationsResetArgs = {
  input?: InputMaybe<ThemeInput>;
};


export type ThemeMutationsSetBackgroundArgs = {
  input: AddBackgroundInput;
};

export enum TimeUnit {
  /** days */
  D = 'D',
  /** hours */
  H = 'H'
}

export type Tour = {
  __typename?: 'Tour';
  name: Scalars['String']['output'];
  showAutomatically: Scalars['Boolean']['output'];
  step: Scalars['PositiveNumber']['output'];
};

export type TourInput = {
  automatic: Scalars['Boolean']['input'];
  finished?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  step: Scalars['PositiveNumber']['input'];
};

/** Analytics (currently Google Analytics only) integration settings */
export type Tracking = {
  __typename?: 'Tracking';
  active: Scalars['Boolean']['output'];
  activeSince?: Maybe<Scalars['DateTime']['output']>;
  /** Tracking code that shoud be present on the analytic code on the survey page */
  code?: Maybe<Scalars['String']['output']>;
};

/** new setting for the submit redirect */
export type TrackingSettingsInput = {
  active: Scalars['Boolean']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
};

/** data for survey traffic on a specific day */
export type TrafficHistoryItem = {
  __typename?: 'TrafficHistoryItem';
  date: Scalars['Date']['output'];
  submits: Scalars['Int']['output'];
  unfinished: Scalars['Int']['output'];
  visits: Scalars['Int']['output'];
};

export type TranslationQueryInput = {
  /** Requested laguauge given as 2-character language ISO code, exception being Brazilian Portuguese (br [normally code for Breton] instead of pt_BR) */
  langCode: Scalars['String']['input'];
  /** Fetch only groups of strings starting with one of these prefixes */
  prefixes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Fetch only these specific translation keys (plus possibly groups specified by above "prefixes" key) */
  singleStrings?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type TranslationStringsInput = {
  /** Fallback language */
  fallbackCode?: InputMaybe<Scalars['LanguageCode']['input']>;
  /** Requested language */
  languageCode: Scalars['LanguageCode']['input'];
  /** Fetch only groups of strings starting with one of these prefixes */
  prefixes?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Fetch only these specific translation keys (plus possibly groups specified by above "prefixes" key) */
  singleStrings?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UnlockedServiceType = {
  __typename?: 'UnlockedServiceType';
  identifier: Scalars['String']['output'];
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated use `identifier` field */
  type: PremiumServiceType;
};

export type UpdateExperimentInput = {
  data?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  state?: InputMaybe<ExperimentStateInput>;
};

export type UpdateResult = {
  __typename?: 'UpdateResult';
  currencyCode?: Maybe<Scalars['String']['output']>;
  isDowngrade?: Maybe<Scalars['Boolean']['output']>;
  isUpgrade?: Maybe<Scalars['Boolean']['output']>;
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>;
  toPayImmediately?: Maybe<Scalars['Float']['output']>;
};

export type UpdateTeamInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  language: Scalars['LanguageCode']['input'];
  name: Scalars['String']['input'];
  ownerLogin?: InputMaybe<Scalars['String']['input']>;
  sso?: InputMaybe<Scalars['Json']['input']>;
  totalSeatsCount?: InputMaybe<Scalars['PositiveNumber']['input']>;
};

export type UpgradeOptions = {
  __typename?: 'UpgradeOptions';
  monthly?: Maybe<UpdateResult>;
  twoYears?: Maybe<UpdateResult>;
  yearly?: Maybe<UpdateResult>;
};

export type UploadDashboardReportInput = {
  dashboardId: Scalars['ID']['input'];
  integration: Scalars['ID']['input'];
  surveyId: Scalars['ID']['input'];
  type: ReportType;
};

/** URL parameter that is tracked and recorded as another data item for respondents of a survey */
export type UrlParameter = {
  __typename?: 'UrlParameter';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** List of tracked URL parameters */
export type UrlParameters = {
  __typename?: 'UrlParameters';
  active: Scalars['Boolean']['output'];
  list: Array<UrlParameter>;
};

export type User = {
  __typename?: 'User';
  activeService: Service;
  country?: Maybe<Country>;
  customLanguages?: Maybe<Array<CustomSurveyLanguage>>;
  email: Scalars['String']['output'];
  emailChangeAllowed: Scalars['Boolean']['output'];
  emailIsVerified: Scalars['Boolean']['output'];
  enviromentConfig?: Maybe<Scalars['Json']['output']>;
  experiments: Array<Maybe<Experiment>>;
  featureList: Array<Scalars['String']['output']>;
  /** @deprecated changed to fullName */
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  getAdminStartSubscriptionUrl: Scalars['Url']['output'];
  getIncreaseSeatsUrl: Scalars['Url']['output'];
  getStartSubscriptionUrl: Scalars['Url']['output'];
  getStripeCheckoutLink: CheckoutSession;
  googleAuthorizationUrl: Scalars['Url']['output'];
  hasIntegrationActive: Scalars['Boolean']['output'];
  hasIntegrationConnected: Scalars['Boolean']['output'];
  hasSubscription: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  inAppContent?: Maybe<Array<Maybe<UserInAppContentType>>>;
  inAppEmails?: Maybe<Array<InAppEmail>>;
  integrations: UserIntegrations;
  interfaceSettings: InterfaceSettings;
  invitation?: Maybe<Team>;
  invitationRecipientLimit: Scalars['Int']['output'];
  invoices: Array<Invoice>;
  /** @deprecated demo is being replaced by trial. Use user.activeService.isTrial instead */
  isDemo: Scalars['Boolean']['output'];
  isSubscription: Scalars['Boolean']['output'];
  /** @deprecated moved to user.activeService */
  isTrial: Scalars['Boolean']['output'];
  /** @deprecated changed to languageCode */
  language: Scalars['String']['output'];
  languageCode: Scalars['LanguageCode']['output'];
  /** @deprecated changed to fullName */
  lastName: Scalars['String']['output'];
  localeCode: Scalars['LocaleCode']['output'];
  newsletter: Scalars['Boolean']['output'];
  notifications?: Maybe<Array<Maybe<Notification>>>;
  pendingEmailPopups: Array<ArchivedMessage>;
  pendingProforma?: Maybe<PendingProforma>;
  phoneNumber: Scalars['String']['output'];
  previousPremiumService?: Maybe<PreviousPremiumService>;
  provider?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated use activeService.responseLimit */
  responseLimit?: Maybe<Scalars['Int']['output']>;
  responseStorage?: Maybe<ResponseStorage>;
  /** @deprecated Use new property experiments */
  shouldShowExtendedYearlyBusinessPromo: Scalars['Boolean']['output'];
  submittedResponsesTotal: Scalars['Int']['output'];
  submittedSurveysThisMonth: Scalars['Int']['output'];
  subscriptionName: Scalars['String']['output'];
  subscriptionPeriod?: Maybe<Scalars['ServicePeriod']['output']>;
  subscriptionReference?: Maybe<Scalars['String']['output']>;
  surveyCount: Scalars['Int']['output'];
  /** List of surveys */
  surveyOffsetConnection?: Maybe<SurveyConnection>;
  systemMessages: Scalars['Boolean']['output'];
  team?: Maybe<Team>;
  tours?: Maybe<Array<Tour>>;
  workspaces: Array<Maybe<WorkspaceWithCount>>;
};


export type UserGetAdminStartSubscriptionUrlArgs = {
  input: StartSubscriptionInput;
};


export type UserGetIncreaseSeatsUrlArgs = {
  element: Scalars['String']['input'];
  quantity: Scalars['PositiveNumber']['input'];
  section: Scalars['String']['input'];
};


export type UserGetStartSubscriptionUrlArgs = {
  additionalSeatsCount?: InputMaybe<Scalars['Int']['input']>;
  element?: InputMaybe<Scalars['String']['input']>;
  extendedFirstPeriod?: InputMaybe<Scalars['Boolean']['input']>;
  freeMonths?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<PremiumServicePeriod>;
  saleSource?: InputMaybe<SaleSourceInput>;
  section?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PremiumServiceType>;
};


export type UserGetStripeCheckoutLinkArgs = {
  input?: InputMaybe<StripeCheckoutLinkInput>;
};


export type UserHasIntegrationActiveArgs = {
  type: IntegrationEnum;
};


export type UserHasIntegrationConnectedArgs = {
  type: IntegrationEnum;
};


export type UserSurveyOffsetConnectionArgs = {
  orderByName?: InputMaybe<SortDirection>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  workspaceId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserEditableInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  systemMessages?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserInAppContentType = {
  __typename?: 'UserInAppContentType';
  displayType?: Maybe<Scalars['String']['output']>;
  eventTriggers: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  timeout?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export type UserIntegrations = {
  __typename?: 'UserIntegrations';
  dropBox: Array<IntegrationCloudStorage>;
  googleDrive: Array<IntegrationGoogle>;
  googleSheets: Array<IntegrationGoogle>;
  oneDrive: Array<IntegrationCloudStorage>;
};

export type UserInterfaceSettingsInput = {
  leftBarAnalyticsCollapsed?: InputMaybe<Scalars['Boolean']['input']>;
  leftBarBuilderCollapsed?: InputMaybe<Scalars['Boolean']['input']>;
  leftBarSettingsCollapsed?: InputMaybe<Scalars['Boolean']['input']>;
};

/** video link */
export type Video = {
  __typename?: 'Video';
  duration: Scalars['String']['output'];
  /** Regional restrictions (list of countries where this video is restricted) */
  restriction?: Maybe<Scalars['String']['output']>;
  /** Thumbnail picture's URL */
  thumb: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
  videoId: Scalars['String']['output'];
};

export enum ViewStyle {
  /** Items are displayed as a grid. */
  Grid = 'GRID',
  /** Items are displayed as a list. */
  List = 'LIST'
}

/** Possible respondent source types */
export enum VisitSource {
  /** Cint respondent panel */
  Cint = 'CINT',
  /** Default survey link shared online or offline */
  Direct = 'DIRECT',
  /** Any of the invitation types */
  Email = 'EMAIL',
  /** Social media - Facebook */
  FbWall = 'FB_WALL',
  /** Social media - Google+ */
  Gplus = 'GPLUS',
  /** Embedded survey on a website */
  Iframe = 'IFRAME',
  /** In App */
  InApp = 'IN_APP',
  /** Social media - Linkedin */
  Linkedin = 'LINKEDIN',
  /** Pop-over widget */
  Popover = 'POPOVER',
  /** Popup on a website */
  Popup = 'POPUP',
  /** Link from QR code */
  QrCode = 'QR_CODE',
  /** Another social network */
  SocNetwork = 'SOC_NETWORK',
  /** Social media - Twitter */
  Twitter = 'TWITTER',
  /** Social media - V Kontakte */
  Vkontakte = 'VKONTAKTE',
  /** Link on a website (button) */
  WebLink = 'WEB_LINK',
  /** Social media - WhatsApp */
  Whatsapp = 'WHATSAPP'
}

export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Input to set Workspace Data */
export type WorkspaceInput = {
  name: Scalars['String']['input'];
};

/** Possible team roles */
export enum WorkspaceRemoveAction {
  MoveToMySurveys = 'MOVE_TO_MY_SURVEYS',
  RemoveSurveys = 'REMOVE_SURVEYS'
}

export type WorkspaceWithCount = {
  __typename?: 'WorkspaceWithCount';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  surveyCount: Scalars['Int']['output'];
};

/** Youtube video */
export type YoutubeInput = {
  duration: Scalars['String']['input'];
  id: Scalars['String']['input'];
  /** Geographical restriction list */
  restriction?: InputMaybe<Scalars['String']['input']>;
  /** Thumbnail picture URL */
  thumb: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Youtube video */
export type YoutubeOutput = {
  __typename?: 'YoutubeOutput';
  duration: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** Geographical restriction list */
  restriction?: Maybe<Scalars['String']['output']>;
  /** Thumbnail picture URL */
  thumb: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ZonedTimestamp = {
  __typename?: 'ZonedTimestamp';
  date: Scalars['String']['output'];
  debug?: Maybe<Scalars['String']['output']>;
  time: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
};

export type ZonedTimestampInput = {
  date: Scalars['String']['input'];
  time: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

/** Input object for rules */
export type LogicRulesInput = {
  action?: InputMaybe<ActionEnum>;
  /** Skip to specific question */
  actionValue?: InputMaybe<Scalars['String']['input']>;
  /** On specific answer (in NPS e.g + for all positives evaluations, or 2 for all values 2 and etc.) */
  conditionValue?: InputMaybe<Scalars['String']['input']>;
  /** Notification. If this filled, then conditionValue should be also. */
  notify?: InputMaybe<Scalars['String']['input']>;
};
