import React, { FC, memo, useCallback, useMemo } from 'react';
import { LOGIC_OPTION_TYPES } from '~/pages/Builder23_1/constants/constants';
import { ToQuestionOrPageStyled } from '~/ui/components/LogicSelect/LogicContents/LogicContents.styled';
import { OptionProps } from '~/pages/Builder23_1/components/LogicDialog/types';
import { Item } from '~/ui/components/LogicSelect/LogicContents/index';
import { LSContentRightProps } from 'ui';
import { useIntl } from 'react-intl';

const ToPage: FC<Omit<LSContentRightProps, 'selected'>> = ({
	options,
	closeSelect,
	choiceId,
	setChoiceLogic,
	logicValue,
}) => {
	const { formatMessage } = useIntl();

	const pages = useMemo(
		() => options.filter((option) => option.value === LOGIC_OPTION_TYPES.TO_PAGE),
		[options],
	);

	const handleOnClick = useCallback(
		(toId: string) => {
			const choiceLogic = {
				action: LOGIC_OPTION_TYPES.TO_PAGE,
				actionValue: toId,
				conditionValue: choiceId,
			};
			setChoiceLogic(choiceLogic);
			closeSelect();
		},
		[choiceId, closeSelect, setChoiceLogic],
	);

	return (
		<ToQuestionOrPageStyled>
			{pages.map((page: OptionProps) => (
				<Item
					key={page?.number}
					onClick={handleOnClick}
					{...page}
					disabled={options[0] === page}
					active={page?.toId === logicValue?.value}
					tooltipContent={formatMessage({
						id: 'app.designer.modal.page-skip-not-possible-hover',
					})}
				/>
			))}
		</ToQuestionOrPageStyled>
	);
};

export default memo(ToPage);
